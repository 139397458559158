import { cb } from 'cb-utils/cbLib';
import { without, difference } from 'lodash';

export interface FetchQueryParms {
  isFiltered?: boolean;
  query: QueryObj;
  systemKey?: string;
  collectionName?: string;
}

export const createCountQuery = (): QueryObj => {
  return cb.Query().setPage(0, 0);
};

export interface CBDiff {
  add?: string[];
  remove?: string[];
}

export function getArrayDiff(startArr: string[], endArr: string[]): CBDiff {
  const endArrStripped = without(endArr, null);
  const startArrStripped = without(startArr, null);
  const add = difference(endArrStripped, startArrStripped);
  const remove = difference(startArrStripped, endArrStripped);
  const result: CBDiff = {};
  if (add) result.add = add;
  if (remove) result.remove = remove;
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyDiff(arr: any[], diff: CBDiff) {
  const withDeleted = difference(arr, diff.remove);
  return [...withDeleted, ...diff.add];
}
