import React from 'react';
import { Field } from 'formik';
import { ICustomSettingComponent } from 'utils/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShowIf = (settingName: string, value: any): ICustomSettingComponent => ({ settings, settingProps }) => {
  if (settings[settingName] === value) {
    return <Field {...settingProps} />;
  } else {
    return null;
  }
};
