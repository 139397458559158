import { Map } from '.';

export interface BaseRoleModel {
  Name: string; // TODO: phase out this one only use lowercase
  name: string;
  Description: string;
}

export interface RoleModel extends BaseRoleModel {
  ID: string;
  Permissions: PermissionsModel;
}

export const emptyRole: RoleModel = {
  ID: '',
  Name: '',
  name: '',
  Description: '',
  Permissions: {
    ExternalDatabases: [],
    AllExternalDatabases: { Level: 0 },
    Collections: [],
    CodeServices: [],
    ServiceCaches: [],
    Portals: [],
    Topics: [],
    UsersList: {
      Level: 0,
    },
    ManageUsers: {
      Level: 0,
    },
    DevicesList: {
      Level: 0,
    },
    Push: {
      Level: 0,
    },
    AllCollections: {
      Level: 0,
    },
    MsgHistory: {
      Level: 0,
    },
    AllServices: {
      Level: 0,
    },
    Triggers: {
      Level: 0,
    },
    Timers: {
      Level: 0,
    },
    Deployments: {
      Level: 0,
    },
    EdgesList: {
      Level: 0,
    },
    Roles: {
      Level: 0,
    },
  },
};

export interface PermissionPrimitive {
  Level: number;
}

export interface CollectionPermissionModel extends BasicListPermissionModel {
  ID: string;
  Columns?: object[]; // note: Columns and Items aren't actually used today
  Items?: object[];
  Name: string;
}

export interface BasicListPermissionModel extends PermissionPrimitive, Map<string | number | object[]> {
  Name: string;
}

export type PermissionsModel = ListPermSections & SimplePermSections;

export interface ListPermSections {
  Collections: CollectionPermissionModel[];
  CodeServices: BasicListPermissionModel[];
  Portals: BasicListPermissionModel[];
  Topics: BasicListPermissionModel[];
  ServiceCaches: BasicListPermissionModel[];
  ExternalDatabases: BasicListPermissionModel[];
}

export interface SimplePermSections {
  UsersList: PermissionPrimitive;
  ManageUsers: PermissionPrimitive;
  DevicesList: PermissionPrimitive;
  AllCollections: PermissionPrimitive;
  Push: PermissionPrimitive; // Note: this isn't actually used anymore
  MsgHistory: PermissionPrimitive;
  AllServices: PermissionPrimitive; // todo: break this up into individual parts
  Deployments: PermissionPrimitive;
  Triggers: PermissionPrimitive;
  Timers: PermissionPrimitive;
  EdgesList: PermissionPrimitive;
  Roles: PermissionPrimitive;
  AllExternalDatabases: PermissionPrimitive;
}

export enum Permissions {
  None = 0,
  Read = 1,
  Create = 2,
  Update = 4,
  Delete = 8,
  All = 15,
}

export enum RemovablePermissions {
  TOPIC = 'topic',
}

export const AllPermissionsCodeForPortals = Permissions.Read + Permissions.Update;
export const AllPermissionsCodeForTopics = Permissions.Read + Permissions.Create;
const AllPermissionsCodeForServices = +Permissions.Read;
export const AllPermissionsCodeForServiceCaches = Permissions.Read + Permissions.Update + Permissions.Delete;
export const AllPermissionsCodeForManageUsers = Permissions.Read + Permissions.Update;
export const AllPermissionsCodeForExternalDatabases = +Permissions.Read;

export const AllPermCodes = {
  CodeServices: AllPermissionsCodeForServices,
  Topics: AllPermissionsCodeForTopics,
  Portals: AllPermissionsCodeForPortals,
  ServiceCaches: AllPermissionsCodeForServiceCaches,
  ManageUsers: AllPermissionsCodeForManageUsers,
  ExternalDatabases: AllPermissionsCodeForExternalDatabases,
};

export const canPerformOperation = (level: number, permission: Permissions) =>
  // tslint:disable-next-line
  (level & permission) === permission;

export const AUTHENTICATED_ROLE = 'Authenticated';
export const ADMINISTRATOR_ROLE = 'Administrator';
export const ANONYMOUS_ROLE = 'Anonymous';

const SimpleRoleFormatter = (value: PermissionPrimitive): UpdatePermissionPrimitive => {
  return {
    permissions: value.Level,
  };
};

const BasicListFormatter = (perms: BasicListPermissionModel[]): BasicUpdateItemPermission[] =>
  perms.map(
    (p): BasicUpdateItemPermission => ({
      permissions: p.Level,
      itemInfo: {
        name: p.Name,
      },
    }),
  );

interface RoleUpdater {
  name: string;
  formatter: (
    perms: PermissionPrimitive | BasicListPermissionModel[],
  ) => UpdatePermissionPrimitive | BasicUpdateItemPermission[];
}

interface RoleUpdateMap extends Map<RoleUpdater> {
  Collections: {
    name: string;
    formatter: (perms: CollectionPermissionModel[]) => UpdateCollectionItemPermission[];
  };
}

export const RoleUpdateMap: RoleUpdateMap = {
  Collections: {
    name: 'collections',
    formatter: (perms: CollectionPermissionModel[]): UpdateCollectionItemPermission[] => {
      return perms.map(
        (cp): UpdateCollectionItemPermission => ({
          permissions: cp.Level,
          itemInfo: {
            id: cp.ID,
            name: cp.Name,
          },
        }),
      );
    },
  },
  Portals: {
    name: 'portals',
    formatter: BasicListFormatter,
  },
  CodeServices: {
    name: 'services',
    formatter: BasicListFormatter,
  },
  Topics: {
    name: 'topics',
    formatter: BasicListFormatter,
  },
  ServiceCaches: {
    name: 'servicecaches',
    formatter: BasicListFormatter,
  },
  MsgHistory: {
    name: 'msgHistory',
    formatter: SimpleRoleFormatter,
  },
  UsersList: {
    name: 'users',
    formatter: SimpleRoleFormatter,
  },
  ManageUsers: {
    name: 'manageusers',
    formatter: SimpleRoleFormatter,
  },
  DevicesList: {
    name: 'devices',
    formatter: SimpleRoleFormatter,
  },
  Deployments: {
    name: 'deployments',
    formatter: SimpleRoleFormatter,
  },
  AllCollections: {
    name: 'allcollections',
    formatter: SimpleRoleFormatter,
  },
  AllServices: {
    name: 'allservices',
    formatter: SimpleRoleFormatter,
  },
  Triggers: {
    name: 'triggers',
    formatter: SimpleRoleFormatter,
  },
  Timers: {
    name: 'timers',
    formatter: SimpleRoleFormatter,
  },
  EdgesList: {
    name: 'edges',
    formatter: SimpleRoleFormatter,
  },
  Roles: {
    name: 'roles',
    formatter: SimpleRoleFormatter,
  },
  ExternalDatabases: {
    name: 'externaldatabases',
    formatter: BasicListFormatter,
  },
  AllExternalDatabases: {
    name: 'allexternaldatabases',
    formatter: SimpleRoleFormatter,
  },
};

export const formatRoleChanges = (original: RoleModel, value: RoleModel): UpdateRoleBody => {
  const rtn: UpdateRoleBody = {
    id: value.ID,
    name: value.Name,
    changes: {},
  };
  const originalPerms = original.Permissions;
  const newPerms = value.Permissions;
  for (const permSection in originalPerms) {
    if (Object.prototype.hasOwnProperty.call(originalPerms, permSection)) {
      // not sure why typescript doesn't know
      const key = permSection as keyof PermissionsModel;
      if (JSON.stringify(originalPerms[key]) !== JSON.stringify(newPerms[key])) {
        if (key === 'Collections') {
          rtn.changes.collections = RoleUpdateMap.Collections.formatter(newPerms[key]);
        } else {
          rtn.changes[RoleUpdateMap[key].name] = RoleUpdateMap[key].formatter(newPerms[key]);
        }
      }
    }
  }
  if (original.Description !== value.Description) {
    rtn.changes.description = value.Description;
  }
  return rtn;
};

export interface UpdatePermissionPrimitive {
  permissions: number;
}
interface ItemInfo {
  name: string;
}
interface CollectionItemInfo extends ItemInfo {
  id: string;
}
interface BasicUpdateItemPermission extends UpdatePermissionPrimitive {
  itemInfo: ItemInfo;
}
interface UpdateCollectionItemPermission extends BasicUpdateItemPermission {
  itemInfo: CollectionItemInfo;
}

export interface RoleChanges
  extends Map<string | BasicUpdateItemPermission[] | UpdatePermissionPrimitive | UpdateCollectionItemPermission[]> {
  description?: string;
  devices?: UpdatePermissionPrimitive;
  users?: UpdatePermissionPrimitive;
  deployments?: UpdatePermissionPrimitive;
  collections?: UpdateCollectionItemPermission[];
  msgHistory?: UpdatePermissionPrimitive;
  triggers?: UpdatePermissionPrimitive;
  timers?: UpdatePermissionPrimitive;
  edgeslist?: UpdatePermissionPrimitive;
  portals?: BasicUpdateItemPermission[];
  services?: BasicUpdateItemPermission[];
}
export interface UpdateRoleBody {
  id: string;
  changes: RoleChanges;
  name?: string; // custom roles hav different name
}
