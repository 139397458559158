import React from 'react';

import { BasicRequestParser } from '../constants';
import { TriggerSourceDefinition } from '../types';
import { MessagingOptions } from 'cb-utils/console-entity-models';
import { TopicSubscribeConditionOptions } from './constants';

const Subscribe: TriggerSourceDefinition = {
  name: MessagingOptions.Subscribe,
  getSummary: defKeyObject => (
    <div>
      <em>{MessagingOptions.Subscribe}</em> to <em>{defKeyObject.topic ? defKeyObject.topic : 'ANY topic'}</em>
    </div>
  ),
  requestParser: BasicRequestParser,
  conditionOptions: TopicSubscribeConditionOptions,
};

export default Subscribe;
