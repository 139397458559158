import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore - no types
import Overflow from 'react-overflow-indicator';

const ScrollableOverflow = styled(Overflow)`
  max-height: ${({ height }) => height};
  .gradient {
    z-index: 10;
    pointer-events: none;
    display: block;
    width: 100%;
    right: 0;
    height: 4rem;
    position: absolute;
  }
  .down-scroll-gradient {
    bottom: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
  .up-scroll-gradient {
    top: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`;

const ScrollableDiv = ({ children, height = '100%' }: { children: React.ReactNode; height: string }) => (
  <ScrollableOverflow height={height} tolerance={20}>
    <Overflow.Content>{children}</Overflow.Content>
    <Overflow.Indicator direction="up">
      <div className="gradient up-scroll-gradient" />
    </Overflow.Indicator>
    <Overflow.Indicator direction="down">
      <div className="gradient down-scroll-gradient" />
    </Overflow.Indicator>
  </ScrollableOverflow>
);

export default ScrollableDiv;
