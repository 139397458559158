exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#007bff",
	"secondary": "#6c757d",
	"success": "#28a745",
	"danger": "#dc3545",
	"white": "#fff",
	"gray100": "#f8f9fa",
	"gray200": "#e9ecef",
	"gray300": "#dee2e6",
	"gray400": "#ced4da",
	"gray500": "#adb5bd",
	"gray600": "#6c757d",
	"gray700": "#495057",
	"gray800": "#343a40",
	"gray900": "#212529",
	"black": "#000",
	"logo_color": "#4c6588",
	"angle_down_icon": "#000",
	"header_background": "#fff",
	"header_text_color": "#000",
	"landing_page_landingPageHeaderBackground": "rgba(0,0,0,0.7)",
	"landing_page_landingPageHeaderTextColor": "#fff",
	"landing_page_loginButton": "#27ebaf",
	"landing_page_loginButtonFontColor": "#000",
	"landing_page_mainContainerFontColor": "#fff",
	"landing_page_registrationFormWrapper": "rgba(0,0,0,0.7)",
	"landing_page_registrationFormHeaderFontColor": "#fff",
	"landing_page_submitButton": "#27ebaf",
	"landing_page_submitButtonFontColor": "#000",
	"landing_page_platformOverviewContainer": "#000",
	"landing_page_platformOverviewFontColor": "#fff",
	"landing_page_platformOverviewServicesFontColor": "#27ebaf",
	"landing_page_platformOverviewOutlineButton": "#000",
	"landing_page_platformOverviewOutlineButtonBorder": "#27ebaf",
	"landing_page_platformOverviewOutlineButtonFontColor": "#27ebaf",
	"landing_page_platformOverviewButton": "#27ebaf",
	"landing_page_platformOverviewButtonFontColor": "#000",
	"landing_page_ipmOverviewContainer": "#f0f0f0",
	"landing_page_ipmOverviewButton": "#27ebaf",
	"landing_page_ipmOverviewButtonFontColor": "#000",
	"landing_page_developerResourcesContainer": "#000",
	"landing_page_developerResourcesFontColor": "#fff",
	"landing_page_developerResourcesServicesFontColor": "#27ebaf",
	"landing_page_developerResourcesButton": "#27ebaf",
	"landing_page_developerResourcesButtonFontColor": "#000"
};