import { createSelector } from 'reselect';
import { STORE_KEY } from './constants';
import { AppState } from 'reducers';

/**
 * Direct selector to the app state domain
 */
const selectAppDomain = () => (state: AppState) => state[STORE_KEY];

/**
 * Other specific selectors
 */

const selectHasFetchedPlatformConfig = () =>
  createSelector(selectAppDomain(), substate => substate.hasFetchedPlatformConfig);

export { selectHasFetchedPlatformConfig };
