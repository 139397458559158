import { getDevTokenFromStorage, getDevTokens } from './devToken';
import { getPlatformInfo } from './platformInfo';
import { getPathParams } from 'cb-utils/getPathParams';

export enum AllowedMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw response;
  }
}

interface FetchConfig extends RequestInit {
  method: AllowedMethods;
}

const attachDevTokenToHeaders = (fetchConfig: FetchConfig, token: string = getDevTokenFromStorage()) => {
  fetchConfig.headers = {
    ...fetchConfig.headers,
    'ClearBlade-DevToken': token,
  };
};

export function platformFetch(
  endpoint: string,
  fetchConfig: FetchConfig,
  token: string = getDevTokenFromStorage(),
  edgeName: string = getPathParams().edgeName,
) {
  attachDevTokenToHeaders(fetchConfig, token);
  const devTokens = getDevTokens(edgeName);
  if (edgeName && devTokens) {
    fetchConfig.headers = {
      'ClearBlade-Edge': devTokens.currentEdge.name,
      'Clearblade-SystemKey': devTokens.currentEdge.systemKey,
      'ClearBlade-DevToken': devTokens.currentEdge.devToken,
    };
  }
  return cbFetch(`${getPlatformInfo().url}${endpoint}`, fetchConfig);
}

export function consoleFetch(url: string, fetchConfig: FetchConfig) {
  attachDevTokenToHeaders(fetchConfig);
  return cbFetch(url, fetchConfig);
}

export function cbFetch(url: string, fetchConfig: FetchConfig) {
  return fetch(url, fetchConfig)
    .then(checkStatus)
    .then(resp => {
      return resp
        .json()
        .then(body => body)
        .catch(() => {
          return '';
        });
    })
    .catch(e => {
      if (!e || !e.json) {
        throw e;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return e.json().then((err: any) => {
        if (err.response && err.response.text) {
          return err.response.text().then((errorText: string) => {
            throw new Error(errorText);
          });
        }
        throw err;
      });
    });
}
