const theme = require('./theme').default;
const themedStyleVars = require(`cssOverrides/${theme}/jsVars`).default;
import baseStyleVars from 'cssOverrides/default/jsVars';
import shadeColor, { darkPercentage, lightPercentage } from 'cb-utils/shadeColor';

const styleVars: typeof baseStyleVars = {
  ...baseStyleVars,
  ...themedStyleVars,
};

styleVars.lightPrimary = styleVars.lightPrimary || shadeColor(styleVars.primary, lightPercentage);
styleVars.darkPrimary = styleVars.darkPrimary || shadeColor(styleVars.primary, darkPercentage);

export default styleVars;
