import { AnyMap, Map } from 'cb-utils/console-entity-models';
import { PARSER_TYPES } from 'containers/Portal/parserTypes';
import { parserFnWrapper } from 'containers/Portal/pluginSettingUtils';
// import EmptyDatasourceError from './utils/EmptyDatasourceError';
import {
  DataSettingDefinition,
  DataSettingInstanceTypes,
  DynamicDataSettingInstance,
  BaseParserInfo,
  ParserInfo,
  ParserInstanceTypes,
  PluginDefinition,
  StaticOrCalculatedDataSettingInstance,
  TypedSettingDefinition,
  DynamicDataSettingInstanceParserInfo,
  SettingTypes,
} from 'utils/types';
import CbObservable from '../../cbObservable';
import DatasourceModel, { DatasourceData } from '../datasource/DatasourceModel';
import PluginPortalModel from '../PluginPortalModel';
import Parsers, { createParserFunction, getParserType } from './utils/Parsers';

export interface SetupOptions {
  calledFromPortalLoad?: boolean;
}

export type PluginInfo = Map<DynamicDataSettingInstance>;

type ModelForAggregatorDatasource = Map<DatasourceModel>;
export interface ThisForIncomingParser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datasource: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevData: any;
  dsModel: DatasourceModel | ModelForAggregatorDatasource;
  model: Plugin<{}>;
}

export interface ThisForOutgoingParser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widget: any;
}

export interface ThisForDatasourceParser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  model: DatasourceModel;
}

export type ThisForParser = ThisForIncomingParser | ThisForOutgoingParser | ThisForDatasourceParser;

// tslint:disable-next-line
type DatasourceSubscriptions = { (): void }[];

abstract class Plugin<PluginSettings extends AnyMap> {
  id = '';
  type = '';
  name = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore - I have no idea why TS is complaining about this - Clark
  settings = CbObservable<PluginSettings>({});
  definition: PluginDefinition;
  parsers = new Parsers();
  latestData = CbObservable();
  lastUpdated: Date;
  datasourceSubscriptions: DatasourceSubscriptions = [];
  externalScripts: string[] = [];
  portalModel: PluginPortalModel;

  constructor(id: string, name: string, settings: AnyMap, portalModel: PluginPortalModel) {
    this.id = id;
    this.name = name;
    this.instantiateSettings(settings);
    this.portalModel = portalModel; // we keep a reference to the portalModel so that we can fetch datasources
  }

  instantiateSettings(settings = {}) {
    this.settings = CbObservable(settings);
    this.settings.subscribe(this.settingsChanged.bind(this));
  }

  async edit(settings: AnyMap, changedSettingType?: SettingTypes) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.processUpdateToSettings(settings as any, changedSettingType);
      this.settings(settings);
    } catch (e) {
      // tslint:disable-next-line
      console.warn(`Failed to process update to plugin'`, settings, e, this);
    }
  }

  isNewType(def: PluginDefinition) {
    return def.type_name !== this.definition.type_name;
  }

  createParserFunction(js: string, isDebug: boolean, id?: string) {
    return createParserFunction(js, isDebug, id);
  }

  createParser(def: TypedSettingDefinition, settingForParser: BaseParserInfo | ParserInfo) {
    try {
      const func = this.createParserFunction(
        settingForParser.value,
        (settingForParser as DynamicDataSettingInstanceParserInfo).incoming_parser
          ? (settingForParser as DynamicDataSettingInstanceParserInfo).incoming_parser.isDebugOn
          : (settingForParser as DynamicDataSettingInstanceParserInfo).outgoing_parser
          ? (settingForParser as DynamicDataSettingInstanceParserInfo).outgoing_parser.isDebugOn
          : (settingForParser as ParserInfo).isDebugOn,
        def.name,
      );
      this.parsers.add(def.name, func);
    } catch (e) {
      const parserType = getParserType(this.definition.settings, e.settingName);
      this.portalModel.dispatcher.createParserError(
        this.id,
        this.definition.type_name,
        e.message,
        e.settingName,
        parserType as ParserInstanceTypes,
      );
      throw e;
    }
  }

  getArgumentsForParser(valueForThis: ThisForParser) {
    return [valueForThis];
  }

  executeParser(settingName: string, valueForThis: ThisForParser) {
    try {
      return this.parsers.execute(settingName, valueForThis, ...this.getArgumentsForParser(valueForThis));
    } catch (e) {
      const parserType = getParserType(this.definition.settings, e.settingName);
      this.portalModel.dispatcher.executeParserError(
        this.id,
        this.definition.type_name,
        this.name,
        e.message,
        e.settingName,
        parserType as ParserInstanceTypes,
      );
      throw e;
    }
  }

  getValueForDatasource(datasource: DatasourceModel): DatasourceData {
    if (datasource.lastUpdated) {
      return {
        newData: datasource.latestData(),
        prevData: datasource.latestData.getPreviousValue(),
      };
    } else {
      // looks like our datasource hasn't updated yet, return nothing
      throw new Error('blah');
      // throw new EmptyDatasourceError(datasource.id);
    }
  }

  // creates an object to be used as the 'this' keyword within a parser
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getThisForParser(newData: any, prevData: any, datasource: DatasourceModel): ThisForIncomingParser {
    return {
      datasource: newData,
      prevData,
      dsModel: datasource ? datasource.getModel() : null,
      model: this,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleDatasourceUpdate(settingName: string, newData: any, prevData: any) {
    // Get the datasource
    const ds = this.portalModel.getDatasourceInstanceById(this.getSettingByName(settingName).id);

    try {
      let processedData = this.executeParser(settingName, this.getThisForParser(newData, prevData, ds));
      if (processedData instanceof Promise) {
        processedData = await processedData;
      }
      this.latestData({
        ...this.latestData(),
        [settingName]: processedData,
      });
      this.lastUpdated = new Date();
    } catch (e) {
      // tslint:disable-next-line
      console.warn(`Failed to handle datasource update for '${settingName}'`, e, this);
    }
  }

  // this method is overridden in some subclasses
  formatDynamicSetting(def: TypedSettingDefinition, setting: DynamicDataSettingInstance) {
    return setting;
  }

  processDynamicSetting(def: TypedSettingDefinition, currentSetting: DynamicDataSettingInstance) {
    const datasource = this.portalModel.getDatasourceInstanceById(currentSetting.id);
    if (!datasource) {
      return; // if there is no datasource, just return undefined for this setting
    }

    // we only want to subscribe to a datasource if this setting has an incoming parser
    if (currentSetting.incoming_parser) {
      const subCallback = this.handleDatasourceUpdate.bind(this, def.name);
      datasource.latestData.subscribe(subCallback); // we subscribe to the datasource so that it can "talk directly" to our plugin
      this.datasourceSubscriptions.push(datasource.latestData.unsubscribe.bind(datasource.latestData, subCallback)); // we keep a reference of a bound function so that we can easily unsubscribe from datasources whenever settings change
    }

    const formattedSetting = this.formatDynamicSetting(def, currentSetting);
    this.createParser(def, formattedSetting.incoming_parser || formattedSetting.outgoing_parser);

    // we only want to execute a parser if this settings has an incoming parser
    if (formattedSetting.incoming_parser) {
      let dsValue: DatasourceData;
      try {
        dsValue = this.getValueForDatasource(datasource);
      } catch (e) {
        return;
        // if (e instanceof EmptyDatasourceError) {
        //   return; // if our datasource is empty, just return undefined for this setting
        // } else {
        //   throw e;
        // }
      }
      return this.executeParser(def.name, this.getThisForParser(dsValue.newData, dsValue.prevData, datasource));
    }
  }

  processCalculatedSetting(def: DataSettingDefinition, currentSetting: ParserInfo) {
    this.createParser(def, currentSetting);
    // when we're processing settings, aka when the user is changing settings or when the widget first loads, we only want to execute incoming parsers
    // e.g., the button widget shouldn't execute its outgoing parser on startup since it should only respond to the click event
    if (def.incoming_parser) {
      return this.executeParser(def.name, this.getThisForParser(null, null, null));
    }
  }

  processStaticSetting(def: TypedSettingDefinition, currentSetting: { value: string }) {
    return currentSetting.value;
  }

  processSetting(def: TypedSettingDefinition, currentSetting: DataSettingInstanceTypes) {
    switch (currentSetting.dataType) {
      case PARSER_TYPES.DYNAMIC:
        return this.processDynamicSetting(def, currentSetting);
      case PARSER_TYPES.CALCULATED:
        return this.processCalculatedSetting(
          def,
          currentSetting as StaticOrCalculatedDataSettingInstance & {
            isDebugOn: boolean;
          },
        );
      case PARSER_TYPES.STATIC:
      default:
        return this.processStaticSetting(def, currentSetting as StaticOrCalculatedDataSettingInstance);
    }
  }

  abstract processUpdateToSettings(newSettings: PluginSettings, changedSettingType?: SettingTypes): object;

  abstract settingsChanged(): void;

  clearSubscriptions() {
    this.datasourceSubscriptions.forEach(unsubCb => {
      unsubCb();
    });
    this.datasourceSubscriptions = [];
  }

  getDefaultValueForSettingByName(defName: string, dataType: PARSER_TYPES) {
    const settingDef = this.getDefinitionForSettingByName(defName);

    const expectedFormat = (settingDef as DataSettingDefinition).expected_format;
    if (expectedFormat) {
      if (dataType === PARSER_TYPES.CALCULATED) {
        try {
          JSON.parse(expectedFormat);
          return `return ${expectedFormat}`;
        } catch (e) {
          // must not have been json, return the primitive in the correct format
          if (!isNaN(parseFloat(expectedFormat))) {
            return `return ${expectedFormat}`;
          } else {
            return `return "${expectedFormat}"`;
          }
        }
      } else if (dataType === PARSER_TYPES.STATIC) {
        return expectedFormat;
      }
    }
    const defaultValue = settingDef.default_value ? settingDef.default_value.value : undefined;
    if (defaultValue) {
      return defaultValue;
    }
    return dataType === PARSER_TYPES.CALCULATED ? parserFnWrapper('return;') : '';
  }

  getSettingByName(settingName: string) {
    return this.settings()[settingName];
  }

  getDefinition() {
    return this.definition;
  }

  getSettingsForDefinition() {
    return this.getDefinition().settings;
  }

  getDefinitionForSettingByName(defName: string) {
    return (
      this.getSettingsForDefinition() &&
      this.getSettingsForDefinition().filter(settingDef => settingDef.name === defName)[0]
    );
  }

  // will be overridden by subclasses
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUpComplete(options: SetupOptions) {
    return;
  }

  async setUp(def: PluginDefinition, settings?: AnyMap, options: SetupOptions = {}) {
    return new Promise((resolve, reject) => {
      this.definition = def;

      const definitionScriptsLoaded = () => {
        const waitForScripts = Promise.all(this.externalScripts.map(this.portalModel.getPromiseForExternalScript));

        waitForScripts.then(async () => {
          if (settings) {
            this.settings(settings);
          }
          const newSettings = settings || this.settings();

          try {
            await this.processUpdateToSettings(newSettings);
          } catch (e) {
            // tslint:disable-next-line:no-console
            console.warn(`Failed to process update to ${def.display_name}`, e, this, newSettings);
          }
          this.setUpComplete(options);
          resolve();
        });
      };

      if (this.definition) {
        this.type = def.type_name;
        if (this.definition.external_scripts) {
          head.js(this.definition.external_scripts.slice(0), definitionScriptsLoaded); // Need to clone the array because head.js adds some weird functions to it
        } else {
          definitionScriptsLoaded();
        }
      } else {
        reject('No definition given for plugin');
      }
    });
  }
}

export default Plugin;
