import { RGLLayoutsExt, RGLBreakpointsExt, LayoutsMap, PaneConfig } from '../types';
import { Layout } from 'react-grid-layout';
import { Map } from 'cb-utils/console-entity-models';

export const iterateOverLayouts = (
  layouts: RGLLayoutsExt,
  layoutItemCb: (l: Layout, size: RGLBreakpointsExt, i: number) => boolean | void,
) => {
  for (const size in layouts) {
    if (Object.prototype.hasOwnProperty.call(layouts, size)) {
      for (let i = 0, len = layouts[size as RGLBreakpointsExt].length; i < len; i += 1) {
        if (layoutItemCb(layouts[size as RGLBreakpointsExt][i], size as RGLBreakpointsExt, i) === false) {
          return;
        }
      }
    }
  }
};

export const getWidgetIdsForPage = (
  pageId: string,
  paneLayouts: LayoutsMap,
  paneGridWidgetLayouts: LayoutsMap,
  panes: Map<PaneConfig>,
): string[] => {
  // so, we need to gather all the widget IDs for widgets that live on a page
  // that means we need to grab the widgets that live directly on a page as well as widgets that live inside panes
  // here's the algo:
  // sort paneLayouts into widgets and panes
  // grab all the tabIds for the panes
  // go through paneGridWidgetLayouts and grab all the widget ids for the tabs
  const widgetsOnPage: string[] = [];
  const panesOnPage: string[] = [];
  iterateOverLayouts(paneLayouts[pageId], l => {
    if (!widgetsOnPage.includes(l.i)) {
      widgetsOnPage.push(l.i);
    } else {
      panesOnPage.push(l.i);
    }
  });
  const tabIds: string[] = [];
  panesOnPage.forEach(p => {
    tabIds.push(...(panes[p] && panes[p].tabInfo && panes[p].tabInfo.tabIds ? panes[p].tabInfo.tabIds : []));
  });
  for (const tabId in paneGridWidgetLayouts) {
    if (tabIds.includes(tabId)) {
      iterateOverLayouts(paneGridWidgetLayouts[tabId], l => {
        if (!widgetsOnPage.includes(l.i)) {
          widgetsOnPage.push(l.i);
        }
      });
    }
  }
  return widgetsOnPage;
};
