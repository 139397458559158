import { AnyMap, ColumnModel, Map } from 'cb-utils/console-entity-models';
import DatasourceClass from 'cb-utils/models/datasource/DatasourceClass';
import DatasourceModel from 'cb-utils/models/datasource/DatasourceModel';
import { PluginInfo } from 'cb-utils/models/plugin/PluginModel';
import WidgetModel from 'cb-utils/models/widget/WidgetModel';
import { PossibleErrors } from 'cb-utils/parseCbError';
import { PARSER_DIRECTIONS } from 'containers/Portal/constants';
import { PARSER_TYPES } from 'containers/Portal/parserTypes';
import { HelpObj } from 'containers/Portal/pluginSettingUtils';
import { WidgetContainers } from 'containers/Portal/types';
import { BaseHTMLModel } from 'plugins/widgets/HTMLWidget/def';
import { MessageDescriptor } from 'react-intl';
import { ObjectSchema } from 'yup';
import { DatasourceEditorProps } from 'components/DatasourceEditor';
import { Field } from 'formik';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type GetComponentPropsWithout<T, PropsToRemove extends object = {}> = T extends
  | React.ComponentType<infer P>
  | React.Component<infer P>
  ? P extends PropsToRemove
    ? PartialBy<P, keyof PropsToRemove>
    : never
  : never;

export type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P> ? P : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export interface CypressTarget {
  ['data-cy']?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CBEvent<T = any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  target: {
    value: T;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  };
}

export interface PluginDefinition {
  type_name: string;
  display_name: string;
  group?: string;
  description: string;
  settings: PluginSettingDefinitions;
  external_scripts?: string[];
  plugin?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>;
  canFullScreen?: boolean;
  canRecommend?: boolean; // note: Ideally this property should only exist on widgetDefinitions. but I'm lazy right now and don't want to make models generic by their definition
  onTypeChange?: (
    settings: PluginInfo,
    pluginDefinition: PluginDefinition,
    widget: WidgetModel<{}>,
    oldSettings?: PluginInfo,
  ) => PluginInfo;
}

export interface DatasourceDefinition extends PluginDefinition {
  class: new (
    settings: SettingsInstance,
    updateCb: DatasourceUpdateCallback,
    errorCb: DatasourceErrorCallback,
  ) => DatasourceClass<{}>;
  icon: () => JSX.Element;
  getDefaultName?: (settings?: AnyMap) => string;
  tags?: string[];
}

export interface WidgetDefinition extends PluginDefinition {
  class: React.ComponentClass<CbWidgetProps<AnyMap, AnyMap>>;
  icon: () => JSX.Element;
  /**
   * Used to for autocomplete in parser: return { widgetData: tsStringData }
   * - if named type needs to be added to internals/transformers/cbParserModelData.ts
   * - and converted to string with "npm run start:transform"
   */
  tsStringData?: string;
  /** Used to for autocomplete in parser: return { overrideSettings: tsStringSetting } */
  tsStringSetting?: string;
  tags?: string[];
}
export type Parser = string | BaseHTMLModel;
export type ParserInstanceTypes = typeof PARSER_DIRECTIONS.INCOMING_PARSER | typeof PARSER_DIRECTIONS.OUTGOING_PARSER;
export type PluginSettingDefinitionTypes =
  | TypedSettingDefinition
  | MultiObjectSettingDefinition
  | OptionSettingDefinition
  | DataSettingDefinition
  | NumberSettingDefinition
  | AsyncSettingDefinition
  | ArraySettingDefinition;

export type PluginSettingDefinitions = PluginSettingDefinitionTypes[];

export type ICustomSettingComponent = (props: {
  onCustomSettingChange: (newSettings: AnyMap) => void;
  settings: AnyMap;
  overrideSettings: AnyMap;
  settingProps?: Parameters<typeof Field>[0];
  dataSettingProps?: DatasourceEditorProps;
}) => JSX.Element;

export interface SettingDefinition {
  name: string;
  display_name?: string | JSX.Element;
  group?: string;
  isThemeable?: boolean;
  description?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default_value?: any;
  simpleHelp?: MessageDescriptor;
  helpObj?: HelpObj;
  xRecommendation?: boolean;
  yRecommendation?: boolean;
  inlineSize?: string;
  size?: number;
  datasources?: string[];
  useSelect?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSettingChange?: (settings: PluginInfo, value: any, data: AnyMap, oldSettings: PluginInfo) => PluginInfo;
  isCell?: boolean;
  CustomSettingComponent?: ICustomSettingComponent;
}

export enum SettingTypes {
  COLOR_TYPE = 'color',
  DATE_PICKER = 'DATE_PICKER',
  DATE_PICKER_IN_SECONDS = 'DATE_PICKER_IN_SECONDS',
  DATA_SETTING_TYPE = 'DATA_SETTING_TYPE',
  OPTION_TYPE = 'option',
  SEARCHABLE_DROPDOWN = 'SEARCHABLE_DROPDOWN',
  MULTI_OBJECT_SETTING_TYPE = 'MULTI_OBJECT_SETTING_TYPE',
  MULTI_INLINE_INPUT = 'MULTI_INLINE_INPUT',
  TEXT_TYPE = 'text',
  TEXT_WITH_SUBMIT = 'textWithSubmit',
  FILE_TYPE = 'file',
  BOOLEAN_TYPE = 'boolean',
  MULTI_DATA_KEY_CHECK_TYPE = 'multiDataKeyCheck',
  TEXT_AREA_TYPE = 'textArea',
  NUMBER_TYPE = 'number',
  DATA_KEY_TYPE = 'dataKey',
  ASYNC_OPTIONS_TYPE = 'optionORtext',
  PARAMETERIZED_TEXT_AREA_TYPE = 'parameterizedTextArea',
  ARRAY_BUILDER_TYPE = 'arrayBuilder',
  IMAGE_URL_INPUT = 'imgUrlInput',
  TAG_INPUT = 'tagInput',
  JSON_BUILDER_TYPE = 'jsonBuilder',
  JSON_STRING_TYPE = 'jsonString',
  KEY_VALUE_BUILDER_TYPE = 'KEY_VALUE_BUILDER_TYPE',
  ASYNC_TYPE_AHEAD_TYPE = 'ASYNC_TYPE_AHEAD_TYPE',
  TYPE_AHEAD_TYPE = 'typeAhead',
  MULTI_TYPE_AHEAD_TYPE = 'MULTI_TYPE_AHEAD_TYPE',
  READ_ONLY_TEXT_TYPE = 'readOnly',
  COPY_TYPE = 'copy',
  PASSWORD_TYPE = 'password',
  SLIDER_TYPE = 'Slider',
  RADIAL_GAUGE = 'RADIAL_GAUGE',
  LARGE_NUMBER = 'LARGE_NUMBER',
  CHECK_OR_EX = 'CHECK_OR_EX',
  TOGGLE = 'TOGGLE',
  BUTTON_SELECT_WITH_OTHER = 'buttonSelectWithOther',
  HTML = 'HTML',
  HTML_STRING = 'HTML_STRING',
  JQUERY_ELEM = 'JQUERY_ELEM',
  BUTTON_GROUP = 'BUTTON_GROUP',
  IMAGE = 'IMAGE',
  ARRAY_OF_ANY_EDITOR = 'ARRAY_OF_ANY_EDITOR',
}

export interface TypedSettingDefinition extends SettingDefinition {
  type: SettingTypes;
}

export interface OptionSettingDefinition extends TypedSettingDefinition {
  options?: SettingOptions;
  activeColor?: string;
}

export interface ArraySettingDefinition extends TypedSettingDefinition {
  arrayType?: SettingTypes.TEXT_TYPE | SettingTypes.NUMBER_TYPE;
}

export interface NumberSettingDefinition extends TypedSettingDefinition {
  min?: number;
  max?: number;
}

export interface MultiObjectSettingDefinition extends TypedSettingDefinition {
  maxLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default_value?: any[];
  singularName?: string;
  objectShape: MultiObjectObjectShape;
}

export type MultiObjectObjectShape = Array<TypedSettingDefinition | OptionSettingDefinition>;

export interface DataSettingDefinition extends TypedSettingDefinition {
  incoming_parser?: boolean;
  outgoing_parser?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expected_format?: any;
  force_data?: PARSER_TYPES[];
  preferred_datasources?: PreferredDatasources;
  split?: boolean;
}

export interface AsyncSettingDefinition extends TypedSettingDefinition {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asyncImpl: () => Promise<any>;
  options: SettingOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SettingOption<T = any> {
  value: T;
  name: string | JSX.Element;
  htmlStringName?: string; // for form widget users
  description?: string; // for form widget users
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default_value?: any;
  'data-cy'?: string;
  customOption?: boolean;
  groupName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SettingOptions<T = any> = Array<SettingOption<T>>;

export type SettingsInstance = AnyMap;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DatasourceUpdateCallback = (payload: any) => void;
export type DatasourceErrorCallback = (title: string, msg: PossibleErrors) => void;

export interface TableRequestConfig {
  method: string;
  query: QueryObj;
  options: {
    schema: ColumnModel[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  causeTrigger?: boolean;
}

interface DataSettingInstance {
  dataType: PARSER_TYPES;
}

export interface StaticOrCalculatedDataSettingInstance extends DataSettingInstance {
  dataType: PARSER_TYPES.CALCULATED | PARSER_TYPES.STATIC;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

interface StaticDataSettingInstance extends StaticOrCalculatedDataSettingInstance {
  dataType: PARSER_TYPES.STATIC;
}

export interface CalculatedDataSettingInstance extends StaticOrCalculatedDataSettingInstance {
  dataType: PARSER_TYPES.CALCULATED;
}

export interface BaseParserInfo {
  value: string; // represents the parser code (defaults to 'return this.datasource')
}

export interface ParserInfo extends BaseParserInfo {
  isDebugOn: boolean;
}

type PreferredDatasources = Map<PreferredDatasourceDefinition>;

interface PreferredDatasourceDefinition {
  parser: string;
  settings: AnyMap;
}

export interface DynamicDataSettingInstanceParserInfo {
  incoming_parser?: ParserInfo;
  outgoing_parser?: ParserInfo;
  force_data?: PARSER_TYPES[];
}

export interface DynamicDataSettingInstanceBase extends DataSettingInstance, DynamicDataSettingInstanceParserInfo {
  dataType: PARSER_TYPES.DYNAMIC;
  preferred_datasources?: PreferredDatasources;
}

export interface DynamicDataSettingInstance extends DynamicDataSettingInstanceBase {
  id: string; // represents the datasource ID this setting is bound to
}

export type DataSettingInstanceTypes =
  | StaticDataSettingInstance
  | CalculatedDataSettingInstance
  | DynamicDataSettingInstance;

export interface DatasourceSuggestions {
  otherInstances: Map<DatasourceModel>;
  preferredInstances: Map<PreferredDatasource>;
}

interface PreferredDatasource extends DatasourceModel {
  defaultParser: string;
  defaultSettings: AnyMap;
}

export interface CbWidgetProps<Settings, Data, Model extends WidgetModel<Settings> = WidgetModel<Settings>> {
  data?: Data;
  settings?: Settings;
  id: string;
  htmlId?: string;
  tabId?: string;
  gridType?: WidgetContainers;
  isInEditMode: boolean;
  isInFullscreenMode?: boolean;
  inEditSettingsModal?: boolean; // remove this when the edit settings modal and temp changes are gone
  model: Model;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WidgetUpdateCallback<T> = (data: any, setting: keyof T) => Promise<{}>;
export type WidgetSettingsInstance<T, I, Model extends WidgetModel<T> = WidgetModel<T>> = CbWidgetProps<T, I, Model>;

/* ----------- taken from https://stackoverflow.com/a/57117594/1870949 ----------- */
// First, define a type that, when passed a union of keys, creates an object which
// cannot have those properties. I couldn't find a way to use this type directly,
// but it can be used with the below type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Impossible<K extends keyof any> = {
  [P in K]: never;
};

// The secret sauce! Provide it the type that contains only the properties you want,
// and then a type that extends that type, based on what the caller provided
// using generics.
export type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;
/* ----------- taken from https://stackoverflow.com/a/57117594/1870949 ----------- */
