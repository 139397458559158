import React from 'react';

import { createText } from 'containers/Portal/pluginSettingUtils';

import { NATIVE_LIBRARIES } from '../../../serviceConstants';
import { AlertProvider } from '../../types';
import { EmailFields } from '../../../AlertTypes/email/types';
import { ProviderCodeGenerator } from '../../../types';

interface MyProviderFields {
  apiKey: string;
}

class SendGridEmailProvider extends AlertProvider<EmailFields, MyProviderFields> {
  createProviderCode(ruleName: string, gen: ProviderCodeGenerator<EmailFields, MyProviderFields>) {
    return `
        var SEND_GRID_API_KEY = "${gen.providerFields.apiKey}";
        var ORIGIN_EMAIL_ADDRESS = "${gen.alertFields.emailFrom}";
        var toField = formatRecipients(${gen.alertFields.emailTo});
        var url = "https://api.sendgrid.com/v3/mail/send";
        var options = {
            url: url,
            headers: {
                "Authorization": "Bearer " + SEND_GRID_API_KEY,
                "Content-Type": 'application/json'
            },
            
            body: JSON.stringify({
                personalizations: [
                    {
                        to: toField,
                        subject: "${gen.alertFields.emailSubject}",
                    },
                ],
                from: {
                    email: ORIGIN_EMAIL_ADDRESS,
                },
                content:[
                    {
                        type: 'text/html',
                        value: "${gen.alertFields.emailBody}",
                    },
                ],
            })
        };
        
        var requestObject = Requests();
        requestObject.post(options, function(err,result) {
            if(err) {
                ${this.callNextAlertOrError(gen)}
            } else {
                ${this.callNextAlertOrSuccess(gen)}
            }
        });
        
        function formatRecipients(recipientList) {
            var field = [];
            for(i in recipientList){
                var email = recipientList[i];
                field.push({email:email});
            }
            return field;
        }
    `;
  }

  getSummary(alertFields: EmailFields) {
    return (
      <div>
        Send email via <em>{this.label}</em> to <em>{alertFields.emailTo.join(', ')}</em>
      </div>
    );
  }

  getErrorCode() {
    return 'resp.error("Sending E-Mail Failed; " + JSON.stringify(err));';
  }
  getSuccessCode() {
    return 'resp.success(result);';
  }
}

export default new SendGridEmailProvider(
  'sendGrid',
  'SendGrid',
  [NATIVE_LIBRARIES.HTTP_LIBRARY],
  [
    {
      ...createText<MyProviderFields>('apiKey', 'API Key'),
      required: true,
    },
  ],
);
