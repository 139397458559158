import React from 'react';
import { CbXAxisProps } from 'components/CbXAxis';
import { CbYAxisProps } from 'components/CbYAxis';
import { WidgetSettingsInstance } from 'utils/types';
import { LegendType, IconType, LayoutType, ReferenceLineProps } from 'recharts';
import { PARSER_TYPES, FORCE_DATA } from 'containers/Portal/parserTypes';
import { DefaultWidgetSettings } from 'cb-utils/models/widget/defaultSettings';
import WidgetModel from 'cb-utils/models/widget/WidgetModel';

export class WidgetClass<
  WidgetSettings,
  WidgetData,
  WidgetState,
  Model extends WidgetModel<WidgetSettings> = WidgetModel<WidgetSettings>,
  InjectedProps = {}
> extends React.Component<WidgetSettingsInstance<WidgetSettings, WidgetData, Model> & InjectedProps, WidgetState> {}

export enum WidgetTypes {
  BUTTON_WIDGET_COMPONENT = 'BUTTON_WIDGET_COMPONENT',
  BAR_CHART_WIDGET_COMPONENT = 'BAR_CHART_WIDGET_COMPONENT',
  LINE_CHART_WIDGET_COMPONENT = 'LINE_CHART_WIDGET_COMPONENT',
  PIE_CHART_WIDGET_COMPONENT = 'PIE_CHART_WIDGET_COMPONENT',
  SCATTER_PLOT_COMPONENT = 'CB_SCATTER_P',
  COLOR_PICKER_WIDGET = 'COLOR_PICKER_WIDGET',
  CRUD_GRID_WIDGET = 'CrudgridWidget',
  FILTERABLE_LIST_WIDGET_COMPONENT = 'FILTERABLE_LIST_WIDGET_COMPONENT',
  FORM_WIDGET_COMPONENT = 'FORM_WIDGET_COMPONENT',
  GROUPING_LIST_WIDGET_COMPONENT = 'GROUPING_LIST_WIDGET_COMPONENT',
  HTML_WIDGET_COMPONENT = 'HTML_WIDGET_COMPONENT',
  INPUT_WIDGET_COMPONENT = 'INPUT_WIDGET_COMPONENT',
  JSON_EDITOR_WIDGET = 'JsonEditorWidget',
  LIST_WIDGET_COMPONENT = 'LIST_WIDGET_COMPONENT',
  RADIAL_GAUGE_WIDGET_COMPONENT = 'RADIAL_GAUGE_WIDGET_COMPONENT',
  SPEEDOMETER_WIDGET = 'SPEEDOMETER_WIDGET',
  TEXT_WIDGET_COMPONENT = 'TEXT_WIDGET_COMPONENT',
  TOGGLE_WIDGET = 'TOGGLE_WIDGET',
  RULE_BUILDER_WIDGET = 'RULE_BUILDER_WIDGET',
  GOOGLE_MAP_WIDGET_COMPONENT = 'GOOGLE_MAP_WIDGET_COMPONENT',
}

export enum WidgetGroups {
  BASIC = 'Basic',
  CHARTS = 'Charts',
  UTILITY = 'Utility',
  MAPS = 'Maps',
  GAUGES = 'Gauges',
}

export enum WidgetSettingGroups {
  TARGET = 'Target',
  STYLE = 'Style',
  X_AXIS = 'X Axis',
  Y_AXES = 'Y Axes',
  DATA = 'Data',
  BARS = 'Bars',
  COLORS = 'Colors',
  REF_LINES = 'Ref Lines',
  GRID = 'Grid',
  BRUSH = 'Brush',
  LEGEND = 'Legend',
  LINES = 'Lines',
  CHART = 'Chart',
  DOTS = 'Dots',
  CONTROLS = 'Controls',
  FORMAT = 'Format',
  SECTIONS = 'Sections',
  LABELS = 'Labels',
  GROUPS = 'Groups',
  CHART_STATISTICS = 'Statistics',
  BULLETS = 'Bullets',
  STATUS_INDICATORS = 'Status Indicators',
  FORM_FIELDS = 'Fields',
}

export interface SourceInfo {
  [key: string]: string | number;
}

export interface ListSourceObject {
  data: SourceDataType;
  highlightId: string;
}

export type SourceDataType = SourceInfo[];

export interface CbChartData {
  chartData: SourceDataType;
}

export interface CbChartSettingsBase extends DefaultWidgetSettings, CbXAxisProps {
  dataKey?: string;
  HAS_UNIQUE_STYLE?: boolean;
  yAxes?: CbYAxisProps[];
  enableStatisticsView?: boolean;
  detectionStrictness?: number;
}

export interface CbChartSettingsExtension extends CbChartSettingsBase {
  legendType?: LegendType;
  name?: string;
  brushStroke?: string;
  referenceLines?: ReferenceLineProps[];
  x?: number;
  y?: number;
  label?: string;
  chartMarginTop?: number;
  chartMarginRight?: number;
  chartMarginBottom?: number;
  chartMarginLeft?: number;
  showBrush?: boolean;
  brushDataKey?: string;
  brushHeight?: number;
  showGrid?: boolean;
  showHorizontalGrid?: boolean;
  showVerticalGrid?: boolean;
  showLegend?: boolean;
  legendColor?: string;
  legendLayout?: LayoutType;
  legendHorizontalAlign?: 'left' | 'center' | 'right';
  legendVerticalAlign?: 'top' | 'middle' | 'bottom';
  legendIconType?: IconType;
  showTooltip?: boolean;
}

export interface CbListSourceData {
  listSource: SourceDataType | ListSourceObject;
}

export interface CbListSettingsBase {
  listSource?: IncomingDataShape;
  eventTarget?: OutgoingDataShape;
  trackingColumnName?: string;
  enableListAutoSelect?: boolean;
  defaultListItem?: string;
  displayAttribute?: string;
  allowFilters?: boolean;
  fontColor?: string;
  listTitle?: string;
  statusAttribute?: string;
  allowSearch?: boolean;
  listItemBackgroundColor?: string;
  activeItemBackgroundColor?: string;
  activeItemFontColor?: string;
  titleColor?: string;
  HAS_UNIQUE_STYLE?: boolean;
  displayBullets?: boolean;
  bulletColor?: string;
  imageIcon?: string;
  displayStatusIndicators?: boolean;
  activeStatusBulletColor?: string;
  activeStatusImageIcon?: string;
  inactiveStatusBulletColor?: string;
  inactiveStatusImageIcon?: string;
  dividerColor?: string;
}

export interface OutgoingDataShape {
  dataType: string;
  forceData: string[];
  id: string;
  outgoing_parser: {
    isDebugOn: boolean;
    value: string;
  };
}

export interface IncomingDataShape {
  dataType: PARSER_TYPES;
  force_data: FORCE_DATA;
  id: string;
  incoming_parser: {
    isDebugOn: boolean;
    value: string;
  };
}
