/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'core-js';
import 'regenerator-runtime/runtime';
import { reportError } from './cb-utils/reportError';
import { Router } from 'react-router-dom';
// eslint-disable-next-line no-constant-condition
if (true) {
  window.onerror = (msg, file, line, col, error) => {
    reportError(msg.toString(), error);
  };
}

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon, the manifest.json file and the .htaccess file
import 'file-loader?name=[name].[ext]!./.htaccess';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import { GlobalStyle } from './global-styles';
import { history } from './appHistory';
import store from './appStore';

import 'cssOverrides/bootstrap';
import { AntDGlobalStyle } from 'cssOverrides/antdOverrides';

const MOUNT_NODE = document.getElementById('app');

const render = (messages: typeof translationMessages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <GlobalStyle />
        <AntDGlobalStyle />
        <Router history={history}>
          <App />
        </Router>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App', './global-styles'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import(
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          'intl/locale-data/jsonp/en.js'
        ),
      ]),
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
