import { detailPageSuffix } from 'cb-utils/consoleRouteUtils';
import { edgesBaseRoute, edgeNameRouteParam } from './edge';
import { deployBaseRoute, DEPLOYMENT_ROUTE_PARAMS, deployDetailRoute } from './deployments';
import { rolesOverviewRoute, rolesDetailRoute } from './roles';
import { devicesOverviewRoute } from './devices';
import { usersOverviewRoute } from './users';
import { exportSystemModalChooseOptionRoute, exportSystemModalDestinationTypeRoute } from './exportSystemModal';
import { adaptersOverviewRoute, adaptersDetailRoute } from './adapters';
import { collectionsOverviewRoute, collectionsDetailRoute } from './collection';
import { codePaths } from './code';

import { systemOverviewRoute, systemDetailRoute } from './system';
import { messagesOverviewRoute, messagesDetailRoute } from './messaging';
import { portalsOverviewRoute, pluginsTabRoute } from './portals';
import { devAccountDetailRoute } from './devAccount';
import { adminDevManageRoute, adminSystemManageRoute } from './admin';
import { IpmAssetTypes } from 'cb-utils/console-entity-models/ipm';
import { sessionsOverviewRoute, sessionsDevicesTabRoute } from './sessions';
import { serviceCacheBaseRoute } from './serviceCache';
import { auditTrailBaseRoute } from './auditTrail';
import { externalDatabasesDetailRoute, externalDatabasesOverviewRoute } from './externalDatabase';
import { timersBaseRoute } from './timers';
import { notificationHistoryRoute } from './notificationHistory';

// note: if you want to add a new route to the app, you should probably add it here so that sidenav and getPathParams know about it
const ConsoleRoutes = {
  SYSTEM_OVERVIEW: systemOverviewRoute,
  SYSTEM_DETAIL: systemDetailRoute,
  EDGE_OVERVIEW: edgesBaseRoute,
  EDGE_DETAIL: `${edgesBaseRoute}/:${edgeNameRouteParam}${detailPageSuffix}`,
  DEPLOY_OVERVIEW: deployBaseRoute,
  DEPLOY_DETAIL: deployDetailRoute,
  DEPLOY_ITEM_LEVEL: `${deployDetailRoute}/:${DEPLOYMENT_ROUTE_PARAMS.ASSET_CLASS_NAME}`,
  ROLES_OVERVIEW: rolesOverviewRoute,
  ROLE_DETAIL: rolesDetailRoute,
  DEVICES_OVERVIEW: devicesOverviewRoute,
  USERS_OVERVIEW: usersOverviewRoute,
  ADAPTERS_OVERVIEW: adaptersOverviewRoute,
  ADAPTERS_DETAIL: adaptersDetailRoute,
  COLLECTION_OVERVIEW: collectionsOverviewRoute,
  COLLECTION_DETAIL: collectionsDetailRoute,
  EXTERNAL_DATABASE_OVERVIEW: externalDatabasesOverviewRoute,
  EXTERNAL_DATABASE_DETAIL: externalDatabasesDetailRoute,
  EXPORT_SYSTEM_MODAL_CHOOSE_DESTINATION_TYPE: exportSystemModalChooseOptionRoute,
  EXPORT_SYSTEM_MODAL_DESTINATION_TYPE: exportSystemModalDestinationTypeRoute,
  MESSAGES_OVERVIEW: messagesOverviewRoute,
  MESSAGES_DETAIL: messagesDetailRoute,
  DEV_ACCOUNT_DETAIL: devAccountDetailRoute,
  ADMIN_DEV_PANEL: adminDevManageRoute,
  ADMIN_SYSTEMS_PANEL: adminSystemManageRoute,
  PORTALS_OVERVIEW: portalsOverviewRoute,
  PLUGINS_TAB: pluginsTabRoute,
  SESSIONS_OVERVIEW: sessionsOverviewRoute,
  SESSIONS_DEVICES_TAB: sessionsDevicesTabRoute,
  SERVICE_CACHE_OVERVIEW: serviceCacheBaseRoute,
  AUDIT_TRAIL: auditTrailBaseRoute,
  TIMERS_OVERVIEW: timersBaseRoute,
  NOTIFICATION_HISTORY_TABLE: notificationHistoryRoute,
  ...codePaths,
};

export const ConsoleRoutesToIpmAssetTypes: {
  [K in keyof IConsoleRoutes]?: IpmAssetTypes[];
} = {
  ROLES_OVERVIEW: [IpmAssetTypes.ROLES],
  ROLE_DETAIL: [IpmAssetTypes.ROLES],
  USERS_OVERVIEW: [IpmAssetTypes.USERS],
  EDGE_OVERVIEW: [IpmAssetTypes.EDGES],
  DEVICES_OVERVIEW: [IpmAssetTypes.DEVICES],
  CODE_OVERVIEW: [IpmAssetTypes.CODE_LIBRARIES],
  LIBRARY_DETAIL: [IpmAssetTypes.CODE_LIBRARIES],
  SERVICE_DETAIL: [IpmAssetTypes.CODE_SERVICES],
  COLLECTION_OVERVIEW: [IpmAssetTypes.COLLECTIONS],
  COLLECTION_DETAIL: [IpmAssetTypes.COLLECTIONS],
  ADAPTERS_OVERVIEW: [IpmAssetTypes.ADAPTERS],
  ADAPTERS_DETAIL: [IpmAssetTypes.ADAPTERS],
  PORTALS_OVERVIEW: [IpmAssetTypes.PORTALS],
  SYSTEM_DETAIL: [],
};

export default ConsoleRoutes;
export type IConsoleRoutes = typeof ConsoleRoutes;

export * from './deployments';
export * from './adapters';
export * from './users';
export * from './devices';
export * from './roles';
export * from './edge';
export * from './collection';
export * from './messaging';
export * from './code';
export * from './portals';
export * from './serviceCache';
