import React from 'react';
import { AnyMap, ColumnType } from 'cb-utils/console-entity-models';
import { ConditionOptions } from '../types';
import buildCondition from 'cb-utils/ruleBuilder/ConditionBuilder';
import { RBCondition } from '../../utils';

const QUERY_ITEM_ID = '<query_item_id>';

export const getTextForSummary = (defKeyObject: AnyMap, verb: string, preposition: string) => {
  if (defKeyObject.collectionName) {
    if (defKeyObject.itemId) {
      return (
        <div>
          <em>Item {verb}</em> {preposition} <em>{defKeyObject.collectionName}</em> where <em>item-id</em> equals{' '}
          <em>{defKeyObject.itemId}</em>
        </div>
      );
    } else {
      return (
        <div>
          <em>Item {verb}</em> {preposition} <em>{defKeyObject.collectionName}</em>
        </div>
      );
    }
  } else {
    return (
      <div>
        <em>Item {verb}</em>
      </div>
    );
  }
};

export const CollectionConditionOptions = [
  {
    name: '<collection_id>',
    value: 'collectionId',
    type: ColumnType.uuid,
    default: true,
  },
  {
    name: '<collection_name>',
    value: 'collectionName',
    type: ColumnType.string,
    default: true,
  },
];

export const CreateItemConditionOptions = [
  {
    name: QUERY_ITEM_ID,
    value: 'items[0].item_id',
    type: ColumnType.uuid,
    default: true,
  },
  ...CollectionConditionOptions,
];

export const UpdateItemConditionOptions = [
  {
    name: QUERY_ITEM_ID,
    value: 'query.FILTERS[0][0].EQ.item_id',
    type: ColumnType.uuid,
    default: true,
  },
  ...CollectionConditionOptions,
];

export const ItemConditionParser = (condition: RBCondition, options: ConditionOptions) => {
  const item_id = options.find(o => o.name === QUERY_ITEM_ID).value;
  return `
  ClearBlade.init(req);
  var query = ClearBlade.Query();
  query.equalTo('item_id', reqObject.${item_id});
  ClearBlade.Collection({ collectionName: reqObject.collectionName }).fetch(query, callBack);

  function callBack (err, data) {
      if(!err) {
          log("Successfully retrieved item");
          ${buildCondition(condition, options, false, true)}
      } else {
          log("Failure while retrieving item; " + JSON.stringify(err));
      }
  }
`;
};
