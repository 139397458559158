import { SystemRouteProps } from 'cb-utils/systemRouteProps';
import ErrorBoundary from 'components/ErrorBoundary';
import ConsoleRoutes from 'containers/Console/routes';
import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';

export interface BaseProtectedRouteProps extends SystemRouteProps<{}> {
  exact?: true;
  path?: string;
  component?: React.LazyExoticComponent<React.ComponentType<{}>>;
  adminRoute?: boolean;
  isAdmin?: boolean;
  useParamsAsKey?: boolean;
}

interface ProtectedRouteProps extends BaseProtectedRouteProps {
  checkAuth: () => Promise<boolean>;
  onCheckAuthError: () => void;
}

interface State {
  completedAuthCheck: boolean;
  tokenIsValid: boolean;
}

class ProtectedRoute extends React.Component<ProtectedRouteProps, State> {
  state = {
    completedAuthCheck: false,
    tokenIsValid: false,
  };

  componentDidMount() {
    this.props
      .checkAuth()
      .then(() => {
        this.setTokenState(true);
      })
      .catch(() => {
        this.props.onCheckAuthError();
        this.setTokenState(false);
      });
  }

  setTokenState = (tokenIsValid: boolean) => this.setState({ tokenIsValid, completedAuthCheck: true });

  render() {
    return (
      <ErrorBoundary>
        <Suspense fallback={<LoadingIndicator forPage />}>{this.getContent()}</Suspense>
      </ErrorBoundary>
    );
  }

  getContent = () => {
    const { tokenIsValid, completedAuthCheck } = this.state;
    if (!completedAuthCheck) {
      return <LoadingIndicator forPage />;
    } else if (tokenIsValid) {
      if (this.props.adminRoute) {
        if (!this.props.isAdmin) {
          return <Redirect to={ConsoleRoutes.SYSTEM_OVERVIEW} />;
        }
      }
      if (this.props.useParamsAsKey) {
        const { component: MyComp, ...otherProps } = this.props;
        // this is so we can go from a page like Role 'Administrator' directly to Role 'Anonymous'
        // and React knows it's a different component that needs to fire componentWillMount
        // and throw away the old page's state
        return (
          <Route
            {...otherProps}
            render={props => {
              return <MyComp {...props} key={JSON.stringify(props.match.params)} />;
            }}
          />
        );
      }
      return <Route key={JSON.stringify(this.props.match.params)} {...this.props} />;
    }
    return <Redirect to="/" />;
  };
}

export default ProtectedRoute;
