import { createArrayBuilder, createParameterizedTextArea, createText } from 'containers/Portal/pluginSettingUtils';

import SendGrid from '../../AlertProviders/email/SendGrid';
import { AvailableAlertTypes, AlertDefinition } from '../types';
import { EmailFields } from './types';
import { SettingTypes } from 'utils/types';

const EmailAlert: AlertDefinition<EmailFields> = {
  name: AvailableAlertTypes.EMAIL,
  label: 'Email',

  providers: [SendGrid],

  fields: [
    {
      ...createText<EmailFields>('emailFrom', 'From E-mail'),
      required: true,
    },
    {
      ...createArrayBuilder<EmailFields>('emailTo', 'To E-mail', {
        arrayType: SettingTypes.TEXT_TYPE,
      }),
      required: true,
    },
    {
      ...createParameterizedTextArea<EmailFields>('emailSubject', 'Subject'),
      required: true,
    },
    {
      ...createParameterizedTextArea<EmailFields>('emailBody', 'Message'),
      required: true,
      description: 'HTML based message',
    },
  ],
};

export default EmailAlert;
