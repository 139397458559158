import { IntlShape } from 'react-intl';
import Yup from 'yup';
import messages from './messages';

export const assetNameValidations = ({ formatMessage }: IntlShape): Yup.StringSchema =>
  Yup.string()
    .required(formatMessage(messages.errorRequired))
    .matches(/^[^\s]+$/, formatMessage(messages.errorSpaces))
    .matches(/^[^\d]/, formatMessage(messages.errorStartWithNum))
    .matches(/^[\w-]+$/, formatMessage(messages.errorSpecialCharacters));

export function validUuidFormat(str: string): boolean {
  return !!str.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/);
}

export const deviceNameValidations = ({ formatMessage }: IntlShape): Yup.StringSchema =>
  Yup.string()
    .required(formatMessage(messages.errorRequired))
    .matches(/^[^\s]+$/, formatMessage(messages.errorSpaces))
    .matches(/^[\w-]+$/, formatMessage(messages.errorSpecialCharacters));

export const minMaxValidations = ({
  min,
  max,
  units,
  formatMessage,
}: {
  min: number;
  max: number;
  units: string;
  formatMessage: IntlShape['formatMessage'];
}): Yup.NumberSchema =>
  Yup.number()
    .min(min, formatMessage(messages.errorMinimum, { min, units }))
    .max(max, formatMessage(messages.errorMaximum, { max, units }));
