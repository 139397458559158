import React from 'react';
import { BasicRequestParser } from '../constants';
import { getTextForSummary, CreateItemConditionOptions, ItemConditionParser } from './utils';
import { DataOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';

const ItemCreated: TriggerSourceDefinition = {
  name: DataOptions.ItemCreated,
  getSummary: defKeyObject => <div>{getTextForSummary(defKeyObject, 'created', 'in')}</div>,
  requestParser: BasicRequestParser,
  conditionOptions: CreateItemConditionOptions,
  getConditionParser: ItemConditionParser,
};

export default ItemCreated;
