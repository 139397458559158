import StackTrace, { StackFrame } from 'stacktrace-js';
import { history } from 'appHistory';
import PortalModel from './models/PortalModel';
import { AppState } from 'reducers';
import { SerializedDatasourceInfo } from './models/datasource/DatasourceModel';
import { Map } from './console-entity-models';
import { WidgetInfo } from './models/widget/WidgetModel';
import { consoleFetch, AllowedMethods } from './cbFetch';
import store from 'appStore';
import { getPlatformInfo, PlatformInfo } from './platformInfo';
import { AppRoutes } from 'containers/App/routes';
const portalModel = PortalModel.getInstance();

function postErrorToServer(
  error: Error,
  stackFrames: StackFrame[],
  state: AppState,
  datasourceInstances: Map<SerializedDatasourceInfo>,
  widgetInstances: Map<WidgetInfo<{}>>,
) {
  return consoleFetch('/console-api/logJSError', {
    method: AllowedMethods.POST,
    body: JSON.stringify({
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      stackframes: JSON.stringify(stackFrames),
      appstate: JSON.stringify(state),
      datasources: JSON.stringify(datasourceInstances),
      widgets: JSON.stringify(widgetInstances),
    }),
  });
}

export async function reportError(
  errorMsg: string,
  error: Error,
  poster: typeof postErrorToServer = postErrorToServer,
  errorState: AppState = store.getState(),
  platformInfo: PlatformInfo = getPlatformInfo(),
) {
  if (isConsoleRoute(history.location.pathname) && !platformInfo.logConsoleJSErrors) {
    return;
  }
  if (isPortalRoute(history.location.pathname) && !platformInfo.logPortalJSErrors) {
    return;
  }
  // todo: make this a per-route thing
  const datasources = portalModel.getSerializableDatasourceInstances();
  const widgets = portalModel.getSerializableWidgetInstances();
  if (error) {
    StackTrace.fromError(error).then(stack => {
      poster(error, stack, errorState, datasources, widgets);
    });
  } else {
    poster(new Error(`Error message: ${errorMsg}`), [], errorState, datasources, widgets);
  }
}

function isConsoleRoute(path: string) {
  return path === '/' || path.indexOf(AppRoutes.CONSOLE_BASE_ROUTE) === 0;
}

function isPortalRoute(path: string) {
  return path.indexOf(AppRoutes.PORTAL_ROUTE) === 0;
}
