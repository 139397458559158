exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#43afd4",
	"secondary": "#a2a5a4",
	"danger": "#b50500",
	"header_background": "#000",
	"header_text_color": "#fff"
};