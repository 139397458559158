import brandLogoUrl from 'img/brand-logos/mcore.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import vars from './bootstrapVars.scss';

// MUST be subset of the export in default/jsVars.js
export default {
  ...vars,
  brandLogoUrl,
  brandLogoWhiteUrl: brandLogoUrl,
  displayPoweredByClearblade: true,
};
