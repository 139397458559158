/**
 *
 * ErrorModal
 *
 */

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import ScrollableModalBody from 'components/ScrollableModalBody';
import parseCbError, { PossibleErrors } from 'cb-utils/parseCbError';
import CbCollapseArea from 'components/CbCollapseArea';
import formatJson from 'cb-utils/services/formatJson';
import CbCodeEditor, { CbEditorLanguages } from 'components/CbCodeEditor';

const InputWrapper = styled.input`
  margin-right: 10px;
`;

const Body = styled.pre`
  white-space: pre-wrap;
  button {
    text-align: left;
    margin-top: 1rem;
  }
  font-family: inherit;
  font-size: inherit;
`;

interface Props {
  title: JSX.Element | string;
  body: PossibleErrors;
  collapsedText?: JSX.Element | string;
  showAcknowledgeCheckbox?: boolean;
  onClose: (info: { acknowledged: boolean }) => void;
  isEditable?: boolean;
}

interface State {
  acknowledged: boolean;
}

class ErrorModal extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    showAcknowledgeCheckbox: false,
  };

  state: State = {
    acknowledged: false,
  };

  render() {
    const error = this.props.body;
    const errorBody = parseCbError(error);

    return (
      <Modal isOpen toggle={this._dismiss} zIndex={1000000000}>
        {/*make sure that this modal shows up on top of others. i.e., the login modal*/}
        <ModalHeader>{this.props.title}</ModalHeader>
        <ScrollableModalBody>
          <Body>
            {typeof errorBody === 'object' ? JSON.stringify(errorBody, null, 2) : errorBody}
            {this.props.collapsedText && this.props.isEditable && (
              <CbCollapseArea
                showText={<FormattedMessage {...messages.more} />}
                hideText={<FormattedMessage {...messages.less} />}
              >
                {this.props.collapsedText}
              </CbCollapseArea>
            )}
            {error !== errorBody && (
              <CbCollapseArea
                showText={<FormattedMessage {...messages.seeFullError} />}
                hideText={<FormattedMessage {...messages.seeFullError} />}
              >
                <CbCodeEditor
                  height={300}
                  value={formatJson(error)}
                  language={CbEditorLanguages.JSON}
                  options={{
                    lineNumbers: 'off' as 'off',
                    readOnly: true,
                    minimap: { enabled: false },
                    automaticLayout: true,
                  }}
                />
              </CbCollapseArea>
            )}
          </Body>
        </ScrollableModalBody>
        <ModalFooter>
          {this.props.showAcknowledgeCheckbox && (
            <div>
              <InputWrapper
                type="checkbox"
                id="acknowledgeError"
                onClick={this._acknowledge}
                checked={this.state.acknowledged}
              />
              <label htmlFor="acknowledgeError">
                <FormattedMessage {...messages.acknowledgeError} />
              </label>
            </div>
          )}
          <Button color="primary" onClick={this._dismiss}>
            <FormattedMessage {...messages.dismiss} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  _dismiss = () => {
    this.props.onClose({
      acknowledged: this.state.acknowledged,
    });
  };

  _acknowledge = () => {
    this.setState({
      acknowledged: !this.state.acknowledged,
    });
  };
}

export default ErrorModal;
