import { call, put, all, takeEvery } from 'redux-saga/effects';

import { storePlatformConfig, PlatformInfo } from 'cb-utils/platformInfo';

import {
  fetchPlatformConfig,
  createFetchPlatformConfigError,
  createFetchPlatformConfigSuccess,
  PlatformConfig,
} from './actions';
import { ActionConstants } from './actionConstants';
import { getObjectDiff } from 'utils/getDiff';

function* callFetchPlatformConfig() {
  try {
    const platformConfig: PlatformConfig = yield call(fetchPlatformConfig);
    if (platformConfig.value) {
      storePlatformConfig(platformConfig.value);
    }
    yield put(createFetchPlatformConfigSuccess(platformConfig.value));
    const currentInfo: PlatformInfo = yield platformConfig.promise;
    const diff = getObjectDiff<PlatformInfo>({
      oldObj: platformConfig.value,
      newObj: currentInfo,
      keysToIgnore: [],
    });
    if (Object.keys(diff).length) {
      storePlatformConfig(currentInfo);
      // tslint:disable-next-line
      console.log('Found diff between stored platform config and fetched platform config. Reloading');
      location.reload();
    }
  } catch (e) {
    yield put(createFetchPlatformConfigError(e));
  }
}

function* fetchPlatformConfigSaga() {
  yield takeEvery(ActionConstants.FETCH_PLATFORM_CONFIG_REQUEST, callFetchPlatformConfig);
}

export default function*() {
  yield all([fetchPlatformConfigSaga()]);
}
