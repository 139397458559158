import { Map, EdgeDevTokenInfo, AnyMap } from 'cb-utils/console-entity-models';

export const EDGES_DEV_LOCAL_STORAGE_KEY = 'ngStorage-cb_available_edges';
export const PLATFORM_DEV_LOCAL_STORAGE_KEY = 'ngStorage-cb_platform_dev_token';
export const REFRESH_TOKEN = 'cb_platform_refresh_token';

export const setDevTokenToStorage = (devToken: string) => {
  localStorage[PLATFORM_DEV_LOCAL_STORAGE_KEY] = JSON.stringify(devToken);
};

export const setRefreshTokenToStorage = (arg: { refresh_token: string; expires_at: number }) => {
  localStorage[REFRESH_TOKEN] = JSON.stringify(arg);
};

export const getRefreshTokenFromStorage = (): { refresh_token?: string; expires_at?: number } => {
  try {
    return JSON.parse(localStorage.getItem('cb_platform_refresh_token')) || {};
  } catch (e) {
    return {};
  }
};

export const getDevTokenFromStorage = (): string => {
  return JSON.parse(localStorage.getItem(PLATFORM_DEV_LOCAL_STORAGE_KEY));
};

export const deleteDevTokenFromStorage = () => {
  delete localStorage[PLATFORM_DEV_LOCAL_STORAGE_KEY];
};

export function getEdgeDevTokenFromStorage(): string {
  return JSON.parse(localStorage.getItem(PLATFORM_DEV_LOCAL_STORAGE_KEY));
}

export function getLoggedInEdges() {
  let edges: Map<EdgeDevTokenInfo> = {};
  try {
    edges = JSON.parse(localStorage.getItem(EDGES_DEV_LOCAL_STORAGE_KEY)) || edges;
  } catch (e) {
    throw Error(e);
  }
  return edges;
}

export function getDevTokens(edgeName: string) {
  const platfrom = getDevTokenFromStorage();
  const edges: Map<EdgeDevTokenInfo> = getLoggedInEdges();
  const currentEdge: EdgeDevTokenInfo = edges[edgeName];
  return { platfrom, edges, currentEdge };
}

interface TokenInfo {
  name: string;
  devToken: string;
  systemKey: string;
}

export function storeAvailableEdges({ name, devToken, systemKey }: TokenInfo) {
  let edgeDevTokens: AnyMap = {};
  try {
    edgeDevTokens = localStorage.getItem(EDGES_DEV_LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(EDGES_DEV_LOCAL_STORAGE_KEY))
      : {};
  } catch (e) {
    console.warn(e); // tslint:disable-line
  }
  edgeDevTokens[name] = { name, devToken, systemKey };
  localStorage.setItem(EDGES_DEV_LOCAL_STORAGE_KEY, JSON.stringify(edgeDevTokens));
}
