import { TableColumn, EntityMap } from 'containers/Console/state';
import { validUuidFormat } from 'utils/validations';
import { SettingTypes } from 'utils/types';
import { validIsoDate } from 'utils/friendlyTime';

export enum ColumnType {
  string = 'string',
  int = 'int',
  bool = 'bool',
  timestamp = 'timestamp',
  float = 'float',
  bigint = 'bigint',
  double = 'double',
  jsonb = 'jsonb',
  blob = 'blob',
  uuid = 'uuid',
}

export interface ColumnModel {
  ColumnName: string;
  ColumnType: ColumnType;
  PK: boolean;
  name?: string;
}

export const formatColumns = (unformatted: TableColumn[]): EntityMap => {
  return unformatted.reduce((acc, cur) => {
    acc[cur.ColumnName] = {
      ...cur,
      name: cur.ColumnName,
    };
    return acc;
  }, {} as EntityMap);
};

export function checkTypeMatch(type: ColumnType, value: string) {
  switch (type) {
    case ColumnType.string:
      return true;
    case ColumnType.uuid:
      if (value.length !== 36) return false;
      return validUuidFormat(value);
    case ColumnType.timestamp:
      if (value.length !== 23) return false;
      return !isNaN(Date.parse(value));
    case ColumnType.bool:
      return value === 'true' || value === 'false';
    case ColumnType.int:
    case ColumnType.bigint: {
      const num = Number(value);
      return !isNaN(num) && Math.round(num) === num;
    }
    case ColumnType.float:
    case ColumnType.double:
      return !isNaN(Number(value));
    default:
      return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function guessColumnType(val: any): ColumnType | 'array' {
  if (typeof val === 'number') {
    if (Math.round(val) === val) {
      return ColumnType.int;
    }
    return ColumnType.float;
  } else if (typeof val === 'boolean') {
    return ColumnType.bool;
  } else if (typeof val === 'string') {
    if (validUuidFormat(val)) {
      return ColumnType.uuid;
    } else if (validIsoDate(val)) {
      return ColumnType.timestamp;
    }
    return ColumnType.string;
  }
  return ColumnType.string;
}

export interface EnrichedColumn extends TableColumn {
  custom: boolean;
  hideFromUser: boolean;
  isDisplayName: boolean;
  name?: string;
  inputType?: SettingTypes;
}

export interface AssetColumnMetadata<AllCols> {
  defaultColumns: AllCols[];
  showInCreateModal: AllCols[];
  requiredInCreateModal: AllCols[];
  noUpdateAllowed: AllCols[];
  hideFromUser?: AllCols[];
  displayName?: AllCols;
}
