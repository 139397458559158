exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#e40c39",
	"secondary": "#458b8f",
	"danger": "#b50500",
	"header_background": "#fff",
	"header_text_color": "#000",
	"body_bg_color": "#fff",
	"body_text_color": "#000",
	"landing_page_loginButton": "#e40c39",
	"landing_page_loginButtonFontColor": "#fff",
	"landing_page_mainContainerFontColor": "#fff",
	"landing_page_registrationFormWrapper": "#fff",
	"landing_page_registrationFormHeaderFontColor": "#000",
	"landing_page_submitButton": "#e40c39",
	"landing_page_submitButtonFontColor": "#fff",
	"landing_page_platformOverviewContainer": "#000",
	"landing_page_platformOverviewFontColor": "#fff",
	"landing_page_platformOverviewServicesFontColor": "#e40c39",
	"landing_page_platformOverviewOutlineButton": "#000",
	"landing_page_platformOverviewOutlineButtonBorder": "#e40c39",
	"landing_page_platformOverviewOutlineButtonFontColor": "#e40c39",
	"landing_page_platformOverviewButton": "#e40c39",
	"landing_page_platformOverviewButtonFontColor": "#000",
	"landing_page_ipmOverviewContainer": "#f0f0f0",
	"landing_page_ipmOverviewButton": "#e40c39",
	"landing_page_ipmOverviewButtonFontColor": "#fff",
	"landing_page_developerResourcesContainer": "#000",
	"landing_page_developerResourcesFontColor": "#fff",
	"landing_page_developerResourcesServicesFontColor": "#e40c39",
	"landing_page_developerResourcesButton": "#e40c39",
	"landing_page_developerResourcesButtonFontColor": "#000"
};