import {
  FaAngleRight as AngleRightIcon,
  FaAngleLeft as AngleLeftIcon,
  FaPlusCircle as PlusCircleIcon,
  FaServer as ServerIcon,
  FaFile as FileIcon,
  FaPaintBrush as PaintBrushIcon,
  FaWrench as WrenchIcon,
  FaAngleDown as AngleDownIcon,
  FaCaretUp as CaretUpIcon,
  FaCaretDown as CaretDownIcon,
  FaQuestionCircle as QuestionCircleIcon,
  FaQuestion as QuestionIcon,
  FaFileCode as CodeFileIcon,
  FaStop as StopIcon,
  FaPlay as PlayIcon,
  FaTrash as TrashIcon,
  FaSearch as SearchIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaGithub as GithubIcon,
  FaCloudUploadAlt as CloudUpload,
  FaCloudDownloadAlt as CloudDownload,
  FaCaretLeft as CaretLeftIcon,
  FaCaretRight as CaretRightIcon,
  FaFilter as FilterIcon,
  FaUpload as UploadIcon,
  FaDownload as DownloadIcon,
  FaMinus as MinusIcon,
  FaPlus as PlusIcon,
  FaRegCircle as CircleOIcon,
  FaCircle as CircleIcon,
  FaRegCheckCircle as CheckCircleIcon,
  FaEdit as EditIcon,
  FaPencilAlt as PencilIcon,
  FaEye as EyeIcon,
  FaEyeSlash as EyeSlashIcon,
  FaChevronCircleDown as ChevronCircleDownIcon,
  FaChevronCircleUp as ChevronCircleUpIcon,
  FaCheck as CheckIcon,
  FaRegSave as SaveIcon,
  FaLongArrowAltLeft as LongArrowLeftIcon,
  FaBalanceScale as BalanceScaleIcon,
  FaChartBar as BarChartIcon,
  FaCog as CogIcon,
  FaExclamationTriangle as ExclamationTriangle,
  FaExclamationCircle as ExclamationCircle,
  FaStar as Star,
  FaChevronLeft as ChevronLeftIcon,
  FaRegWindowMaximize as PaneIcon,
  FaRegEyeSlash as ToggleEyeOff,
  FaRegEye as ToggleEye,
  FaHtml5 as HTML5Icon,
  FaBug as BugIcon,
  FaExpandArrowsAlt as FullScreenIcon,
  FaCogs as CogsIcon,
} from 'react-icons/fa';
import { IoLogoJavascript as JSIcon, IoLogoCss3 as CSSIcon, IoMdMove as MoveIcon } from 'react-icons/io';
import {
  MdEmail as EmailIcon,
  MdReorder as ReorderIcon,
  MdMoreVert as EllipsisVerticalIcon,
  MdMoreHoriz as EllipsisHorizontalIcon,
  MdSms as SMSIcon,
  MdKeyboardReturn as KeyboardReturnIcon,
  MdRefresh as ResetIcon,
  MdMenu as MenuIcon,
  MdSmartphone as SmartPhoneIcon,
  MdTablet as TabletIcon,
  MdComputer as DesktopIcon,
  MdSend as SendIcon,
  MdDone as DoneIcon,
  MdReportProblem as ReportProblemIcon,
} from 'react-icons/md';
import { TiClipboard as ClipboardIcon, TiExport as ExportIcon } from 'react-icons/ti';
import { GoClippy as CopyIcon, GoGitBranch as BranchIcon } from 'react-icons/go';

export {
  PlusCircleIcon,
  PlusIcon,
  MinusIcon,
  DownloadIcon,
  UploadIcon,
  ResetIcon as RefreshIcon,
  FilterIcon,
  CaretLeftIcon,
  CaretRightIcon,
  CaretDownIcon,
  CaretUpIcon,
  CloudDownload,
  CloudUpload,
  GithubIcon,
  ExternalLinkIcon,
  ExportIcon,
  SearchIcon,
  TrashIcon,
  PlayIcon,
  StopIcon,
  ResetIcon,
  CodeFileIcon,
  QuestionCircleIcon,
  QuestionIcon,
  KeyboardReturnIcon,
  SMSIcon,
  EmailIcon,
  ClipboardIcon,
  AngleDownIcon,
  WrenchIcon,
  PaintBrushIcon,
  FileIcon,
  EllipsisVerticalIcon,
  ServerIcon,
  JSIcon,
  CSSIcon,
  ReorderIcon,
  AngleLeftIcon,
  AngleRightIcon,
  FullScreenIcon,
  CopyIcon,
  CircleOIcon,
  CheckCircleIcon,
  EditIcon,
  PencilIcon,
  EyeIcon,
  EyeSlashIcon,
  ChevronCircleDownIcon,
  ChevronCircleUpIcon,
  CheckIcon,
  SaveIcon,
  EllipsisHorizontalIcon,
  LongArrowLeftIcon,
  BalanceScaleIcon,
  BarChartIcon,
  CogIcon,
  ExclamationTriangle,
  ExclamationCircle,
  Star,
  MenuIcon,
  ChevronLeftIcon,
  SmartPhoneIcon,
  TabletIcon,
  DesktopIcon,
  PaneIcon,
  ToggleEye,
  ToggleEyeOff,
  HTML5Icon,
  MoveIcon,
  BugIcon,
  BranchIcon,
  SendIcon,
  DoneIcon,
  ReportProblemIcon,
  CircleIcon,
  CogsIcon,
};
