import { AnyMap } from '.';

// const example1: TriggerModel = {
//   system_key: '90d7fba60b9cde91d3e6fbd1d78501',
//   system_secret: 'EC8E90A70B9AC28EFDE3A0CBBEB001',
//   name: 'updated',
//   event_definition: {
//     def_module: DefModuleOptions.User,
//     def_name: UserOptions.UserUpdated,
//     def_keys: ['userId'],
//     visibility: true,
//   },
//   key_value_pairs: { userId: '8cdb96aa0beaddcad9b99a938c9201' },
//   service_name: 'asdfsdf',
// };

// const example2: TriggerModel = {
//   system_key: '90d7fba60b9cde91d3e6fbd1d78501',
//   system_secret: 'EC8E90A70B9AC28EFDE3A0CBBEB001',
//   name: 'another',
//   event_definition: {
//     def_module: 'Asset',
//     def_name: 'AssetCreated',
//     def_keys: ['assetClass'],
//     visibility: true,
//   },
//   key_value_pairs: {},
//   service_name: 'asdfsdf',
// };
// const example3: TriggerModel = {
//   system_key: '90d7fba60b9cde91d3e6fbd1d78501',
//   system_secret: 'EC8E90A70B9AC28EFDE3A0CBBEB001',
//   name: 'data',
//   event_definition: {
//     def_module: 'Data',
//     def_name: 'CollectionUpdated',
//     def_keys: ['collectionId', 'collectionName'],
//     visibility: true,
//   },
//   key_value_pairs: {
//     collectionId: 'f08892ae0be48bb5fcb889f7f8c501',
//     collectionName: 'colors',
//   },
//   service_name: 'asdfsdf',
// };
// const example4: TriggerModel = {
//   system_key: '90d7fba60b9cde91d3e6fbd1d78501',
//   system_secret: 'EC8E90A70B9AC28EFDE3A0CBBEB001',
//   name: 'mytopic',
//   event_definition: {
//     def_module: 'Messaging',
//     def_name: 'Publish',
//     def_keys: ['topic'],
//     visibility: true,
//   },
//   key_value_pairs: { topic: 'mytopic' },
//   service_name: 'asdfsdf',
// };

export enum DefModuleOptions {
  Data = 'Data',
  Messaging = 'Messaging',
  User = 'User',
  Device = 'Device',
  StartConnectDisconnect = 'StartConnectDisconnect',
}

export enum DeviceOptions {
  DeviceCreated = 'DeviceCreated',
  DeviceUpdated = 'DeviceUpdated',
  DeviceDeleted = 'DeviceDeleted',
}

export enum DataOptions {
  CollectionCreated = 'CollectionCreated',
  CollectionUpdated = 'CollectionUpdated',
  CollectionDeleted = 'CollectionDeleted',
  ItemCreated = 'ItemCreated',
  ItemUpdated = 'ItemUpdated',
  ItemDeleted = 'ItemDeleted',
}

export enum AssetOptions {
  AssetCreated = 'AssetCreated',
  AssetUpdated = 'AssetUpdated',
  AssetDeleted = 'AssetDeleted',
}

export enum MessagingOptions {
  Publish = 'Publish',
  Subscribe = 'Subscribe',
  Unsubscribe = 'Unsubscribe',
  MQTTUserConnected = 'MQTTUserConnected',
  MQTTUserDisconnected = 'MQTTUserDisconnected',
  MQTTDeviceConnected = 'MQTTDeviceConnected',
  MQTTDeviceDisconnected = 'MQTTDeviceDisconnected',
}

export enum UserOptions {
  UserCreated = 'UserCreated',
  UserUpdated = 'UserUpdated',
  UserDeleted = 'UserDeleted',
}

export enum StartConnectDisconnectOptions {
  PlatformStarted = 'PlatformStarted',
  EdgeStarted = 'EdgeStarted',
  PlatformConnectedOnEdge = 'PlatformConnectedOnEdge',
  PlatformDisconnectedOnEdge = 'PlatformDisconnectedOnEdge',
  EdgeConnectedOnPlatform = 'EdgeConnectedOnPlatform',
  EdgeDisconnectedOnPlatform = 'EdgeDisconnectedOnPlatform',
}

export type DefNameOptions =
  | UserOptions
  | AssetOptions
  | MessagingOptions
  | DataOptions
  | DeviceOptions
  | StartConnectDisconnectOptions;

export const defNameFromModuleOptions = {
  Device: Object.values(DeviceOptions),
  Data: Object.values(DataOptions),
  Asset: Object.values(AssetOptions),
  Messaging: Object.values(MessagingOptions),
  User: Object.values(UserOptions),
  StartConnectDisconnect: Object.values(StartConnectDisconnectOptions),
};

export interface TriggerEventDefinition {
  def_module: DefModuleOptions;
  def_name: DefNameOptions;
  def_keys: string[];
  visibility: boolean;
}

export interface TriggerModel {
  system_key: string;
  system_secret: string;
  name: string;
  event_definition: TriggerEventDefinition;
  key_value_pairs: AnyMap;
  service_name: string;
}

export interface TriggerPayload {
  def_module: string; // not nested under event definition
  def_name: string; // not nested under event definition
  system_key: string;
  name: string;
  service_name: string;
  key_value_pairs: object;
}
