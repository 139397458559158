/*
 *
 * App
 *
 */

import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { FormattedMessage } from 'react-intl';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './sagas';
import { createFetchPlatformConfigRequest } from './actions';
import { selectHasFetchedPlatformConfig } from './selectors';
// import messages from './messages';

import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import CbPortal from 'containers/CbPortal/Loadable';
import Console from 'containers/Console/Loadable';
import NotFound from 'containers/NotFoundPage/Loadable';
import { STORE_KEY } from './constants';
import LoadingIndicator from 'components/LoadingIndicator';
import LandingPage from 'containers/LandingPage/Loadable';
import { AppRoutes } from './routes';
import { getDevTokenFromStorage } from 'cb-utils/devToken';
import ConsoleRoutes from '../Console/routes';
import ProtectedRoute from 'components/ProtectedRoute';
import { createStructuredSelector } from 'reselect';
import TwoFactorAuthValidationPage from 'components/TwoFactorAuthValidationPage';

type AppProps = RouteComponentProps<{}>;

export class App extends React.Component<AppProps & StateToProps & DispatchToProps, {}> {
  hasLoaded = false;

  componentDidMount() {
    this.props.init();
  }

  shouldComponentUpdate(nextProps: AppProps) {
    const shouldUpdate = nextProps.location.pathname !== this.props.location.pathname || !this.hasLoaded;
    if (!this.hasLoaded) {
      this.hasLoaded = true;
    }
    return shouldUpdate;
  }

  render() {
    if (this.props.hasFetchedPlatformConfig) {
      return (
        // Suspense around entire app in case any children don't have their own and use React.lazy
        <Suspense fallback={<LoadingIndicator />}>
          <Switch>
            <Route path={AppRoutes.PORTAL_ROUTE} component={CbPortal} />
            <Route exact path={AppRoutes.DEV_2FA_VALIDATION_ROUTE} component={TwoFactorAuthValidationPage} />
            {/* so we will have access to system and edge params everywhere*/}
            <ProtectedRoute path={AppRoutes.CONSOLE_SYSTEM_ROUTE} component={Console} />
            <ProtectedRoute path={AppRoutes.CONSOLE_BASE_ROUTE} component={Console} />
            <Route
              exact
              path="/"
              render={() =>
                getDevTokenFromStorage() ? <Redirect to={ConsoleRoutes.SYSTEM_OVERVIEW} /> : <LandingPage />
              }
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      );
    } else {
      return <LoadingIndicator />;
    }
  }
}

type StateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = createStructuredSelector({
  hasFetchedPlatformConfig: selectHasFetchedPlatformConfig(),
});
type DispatchToProps = typeof mapDispatchToProps;

const mapDispatchToProps = {
  init: createFetchPlatformConfigRequest,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: STORE_KEY, reducer });
const withSaga = injectSaga({ key: STORE_KEY, saga });

export default compose(withRouter, withReducer, withSaga, withConnect)(App);
