import { REQ_OBJECT, createAlertCaller } from '../serviceConstants';
import {
  combinatorMap,
  getSummary,
  isRBCondition,
  isRBRule,
  Rule,
} from 'cb-utils/console-entity-models/FilterCondition';
import { ConditionOptions, ArithmeticTypes } from 'cb-utils/ruleBuilder/TriggerSources/types';
import { RBCondition, RBRule } from 'cb-utils/ruleBuilder/utils';
import { ColumnType } from '../../console-entity-models';

const NO_CONDITION = 'true';

enum BooleanValue {
  true = 'true',
  false = 'false',
}

export const createConditionFromRule = (
  isSummaryMode: boolean,
  options: ConditionOptions,
  asString: boolean,
  rule: RBCondition | RBRule | Rule,
): string => {
  if (isRBCondition(rule)) {
    if (rule.rules.length > 0) {
      return `(${rule.rules
        .map(createConditionFromRule.bind(this, isSummaryMode, options, asString))
        .join(` ${combinatorMap[rule.combinator]} `)})`;
    } else {
      return NO_CONDITION; // supports the case when users don't want any conditions on calling an alert
    }
  } else if (isRBRule(rule)) {
    const field = rule.field.base;
    if (isSummaryMode) {
      return getSummary(rule, options);
    }
    let condition: string;
    if ((field.type in ArithmeticTypes || field.type === ColumnType.bool) && rule.field.remainder === '' && !asString) {
      condition = `${REQ_OBJECT}.${field.value}${rule.field.remainder} ${rule.operator} ${rule.value.content}`;
    } else {
      condition = `tryParse(${REQ_OBJECT}.${field.value})${rule.field.remainder} ${rule.operator}`;
      if (rule.value.content in BooleanValue) {
        condition += ` ${rule.value.content}`;
      } else {
        condition += ` "${rule.value.content}"`;
      }
    }
    return condition;
  } else {
    // old portals
    return `${REQ_OBJECT}.${rule.field} ${rule.operator} ${rule.value}`;
  }
};

// For cases where we might be looking at fetched data instead of data stored on the reqObject
export const createConditionFromData = (options: ConditionOptions, rule: RBCondition | RBRule): string => {
  if (isRBCondition(rule)) {
    if (rule.rules.length > 0) {
      return `(${rule.rules
        .map(createConditionFromData.bind(this, options))
        .join(` ${combinatorMap[rule.combinator]} `)})`;
    } else {
      return NO_CONDITION;
    }
  } else if (isRBRule(rule)) {
    let field = rule.field.base;
    if (field.default) {
      field = {
        ...field,
        value: `${REQ_OBJECT}.${field.value}`,
      };
    }
    let condition = `${field.value}${rule.field.remainder} ${rule.operator} `;
    if (
      rule.value.content in BooleanValue ||
      ((field.type === ColumnType.bool || field.type in ArithmeticTypes) && rule.field.remainder === '')
    ) {
      condition += `${rule.value.content}`;
    } else {
      condition = `tryParse(${field.value})${rule.field.remainder} ${rule.operator} "${rule.value.content}"`;
    }
    return condition;
  }
};

export const createConditionFormattedForSummary = (condition: string) => {
  if (condition === NO_CONDITION) {
    return condition;
  }
  const format = condition.split('<').join('&lt;');
  return format.split('>').join('&gt;');
};

export const getFieldsInCondition = (condition: RBCondition) => {
  const rtn: string[] = [];
  function mapper(rule: RBCondition | RBRule) {
    if ((rule as RBCondition).rules) {
      (rule as RBCondition).rules.map(mapper);
    } else {
      rtn.push((rule as RBRule).field.base.value as string);
    }
  }
  if (condition) {
    mapper(condition);
  }
  return rtn;
};

const buildCondition = (
  query: RBCondition | RBRule,
  options: ConditionOptions,
  asString: boolean,
  fetchedData?: boolean,
) => {
  const condition = fetchedData
    ? createConditionFromData(options, query)
    : createConditionFromRule(false, options, asString, query);
  return `if (${condition}) {
	  ${createAlertCaller(0)}();
  }
  `;
};

export default buildCondition;
