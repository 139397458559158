import { createArrayBuilder, createParameterizedTextArea, createText } from 'containers/Portal/pluginSettingUtils';

import Twilio from '../../AlertProviders/sms/Twilio';
import { AvailableAlertTypes, AlertDefinition } from '../types';
import { SMSFields } from './types';
import { SettingTypes } from 'utils/types';

const SMSAlert: AlertDefinition<SMSFields> = {
  name: AvailableAlertTypes.SMS,
  label: 'SMS',

  providers: [Twilio],

  fields: [
    {
      ...createText<SMSFields>('smsFrom', 'From Phone #'),
      required: true,
    },
    {
      ...createArrayBuilder<SMSFields>('smsTo', 'To Phone #', {
        arrayType: SettingTypes.TEXT_TYPE,
      }),
      required: true,
    },
    {
      ...createParameterizedTextArea<SMSFields>('smsBody', 'Message'),
      required: true,
    },
  ],
};

export default SMSAlert;
