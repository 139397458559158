import {
  executeParserError,
  createParserError,
  widgetUpdated as portalWidgetUpdated,
  datasourceDataUpdated,
  handleDatasourceError,
  savePortalRequest,
} from '../../containers/Portal/actions';
import { ParserInstanceTypes } from 'utils/types';
import { ExternalResourceWithPromise } from '../externalResourceUtils';
import PortalModel from './PortalModel';
import DatasourceModel from './datasource/DatasourceModel';

// this is the 'portalModel' that is passed into plugins (which is really just a slimmed down version of the real portalModel)
type PluginPortalModel = ReturnType<typeof createPluginApi>;
export function createPluginApi(this: PortalModel, widgetUpdated: typeof portalWidgetUpdated = portalWidgetUpdated) {
  return {
    dispatcher: {
      savePortal: () => {
        this.dispatch(savePortalRequest());
      },
      widgetUpdated: (id: string, time: number) => {
        this.dispatch(widgetUpdated(id, time));
      },
      executeParserError: (
        id: string,
        type: string,
        widgetName: string,
        message: string,
        settingName: string,
        parserType: ParserInstanceTypes,
      ) => {
        this.dispatch(executeParserError(id, type, widgetName, message, settingName, parserType));
      },
      createParserError: (
        id: string,
        type: string,
        message: string,
        settingName: string,
        parserType: ParserInstanceTypes,
      ) => {
        this.dispatch(createParserError(id, type, message, settingName, parserType));
      },
      datasourceDataUpdated: (id: string, time: number) => {
        this.dispatch(datasourceDataUpdated(id, time));
      },
      handleDatasourceError: (args: ReturnType<typeof handleDatasourceError>['payload']) => {
        this.dispatch(handleDatasourceError(args));
      },
    },
    setCustomWidgetLayout: this.setCustomWidgetLayout,
    getDatasourceInstancesWithNameAsKey: this.getDatasourceInstancesWithNameAsKey,
    getCustomPaneNameObject: this.getCustomPaneNameObject,
    getDatasourceInstanceById: this.getDatasourceInstanceById,
    updateAggregateDatasources: this.updateAggregateDatasources,
    getExternalScripts: this.getExternalScripts,
    getPromiseForExternalScript: this.getPromiseForExternalScript,
  };
}

export const createPluginApiForTest = () =>
  createPluginApi.call({
    getPromiseForExternalScript: () => {
      return Promise.resolve();
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore - it's just for a test stub, right?
    dispatch: () => {
      return;
    },
    updateAggregateDatasources: () => {
      return;
    },
    getExternalScripts: (): ExternalResourceWithPromise[] => {
      return [];
    },
    getDatasourceInstancesWithNameAsKey: () => ({}),
    getCustomPaneNameObject: () => ({}),
    setCustomWidgetLayout: () => {
      return;
    },
    getDatasourceInstanceById: (): DatasourceModel => {
      return null;
    },
  });

export default PluginPortalModel;
