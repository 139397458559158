/**
 *
 * PluginWidgetWrapper
 *
 */

import React, { ComponentClass, ClassicComponentClass } from 'react';
import isEqual from 'lodash/isEqual';
import { WidgetSettingsInstance } from 'utils/types';
import { AnyMap } from 'cb-utils/console-entity-models';

export interface PluginWidgetProps extends WidgetSettingsInstance<AnyMap, AnyMap> {
  func?: ComponentClass | ClassicComponentClass;
}

interface PluginWidgetState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pluginInstance: any;
}

const PluginWidgetWrapper = (func: ComponentClass | ClassicComponentClass) => (props: PluginWidgetProps) => {
  return <PluginWidget func={func} {...props} />;
};

class PluginWidget extends React.Component<PluginWidgetProps, PluginWidgetState> {
  ele: HTMLDivElement;

  state: PluginWidgetState = {
    pluginInstance: null,
  };

  componentDidMount() {
    this.setState({
      pluginInstance: new this.props.func(this.props.settings, this.props.model.updateCallback),
    });
  }

  componentDidUpdate(prevProps: PluginWidgetProps) {
    if (!isEqual(prevProps.settings, this.props.settings)) {
      this._callPluginMethod('onSettingsChanged', prevProps.settings);
    }
    if (!isEqual(prevProps.data, this.props.data)) {
      this._callPluginMethod('onCalculatedValueChanged', prevProps.data);
    }
  }

  componentWillUnmount() {
    this._callPluginMethod('onDispose');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _callPluginMethod = (method: string, input: any = null) => {
    if (this.state.pluginInstance[method]) {
      return this.state.pluginInstance[method](input);
    } else {
      alert(`Please add a ${method} method to this plugin`);
    }
  };

  render() {
    return (
      <div
        ref={ele => {
          this.ele = ele;
        }}
        style={{ width: '100%', height: '100%' }}
      >
        {this.ele && (
          <div
            dangerouslySetInnerHTML={{
              __html: this._callPluginMethod('render', this.ele),
            }}
          />
        )}
      </div>
    );
  }
}

export default PluginWidgetWrapper;
