import { findIndex } from 'lodash';
import { EntityModels } from 'containers/Console/state';

export interface AssetConfig {
  asset_class: keyof EntityModels;
  asset_id: string;
  // deployment: string;
  // member: string;
  sync_to_edge: boolean;
  sync_to_platform: boolean;
}

export enum SpecialDeploymentKeys {
  ALL_EDGES = '___ALL_EDGES___',
  ALL_ASSETS_IN_CLASS = '',
}

export enum SyncStrategies {
  NO_SYNC = 'NO_SYNC',
  DEPLOY_TABLE = 'DEPLOY_TABLE',
  DEPLOY_AND_SYNC_BOTH = 'DEPLOY_AND_SYNC_BOTH',
  DEPLOY_AND_SYNC_TABLE_TO_EDGE = 'DEPLOY_AND_SYNC_TABLE_TO_EDGE',
  DEPLOY_AND_SYNC_TABLE_TO_PLATFORM = 'DEPLOY_AND_SYNC_TABLE_TO_PLATFORM',
  ITEM_LEVEL_SYNC = 'ITEM_LEVEL_SYNC',
}

export type DeploymentEdges = string[];

export const emptyDeploymentConfig: DeploymentModel = {
  name: '',
  description: '',
  assets: [],
  edges: [],
};

export interface DeploymentModel {
  name: string;
  description: string;
  assets: AssetConfig[];
  edges: DeploymentEdges;
}

export interface DeploymentUpdateBody {
  assets: {
    add: AssetConfig[];
    remove: AssetConfig[];
  };
  edges: {
    add: DeploymentEdges;
    remove: DeploymentEdges;
  };
  description?: string;
}

export const doesAssetClassExist = (assets: AssetConfig[], assetClass: string) => {
  return (
    findIndex(assets, {
      asset_class: assetClass,
    }) > -1
  );
};

export const doesAssetExist = (assets: AssetConfig[], assetId: string, extraSearchProps = {}) => {
  return (
    findIndex(assets, {
      asset_id: assetId,
      ...extraSearchProps,
    }) > -1
  );
};

const isConfiguredForAllAssetsInClass = (assets: AssetConfig[], extraSearchProps = {}) => {
  return doesAssetExist(assets, SpecialDeploymentKeys.ALL_ASSETS_IN_CLASS, extraSearchProps);
};

export const shouldDeployAllAssetsForClass = (assets: AssetConfig[]) => isConfiguredForAllAssetsInClass(assets);

export const shouldSyncAllAssetsForClass = (
  assets: AssetConfig[],
  directionProps: { sync_to_edge?: boolean; sync_to_platform?: boolean },
) => {
  return isConfiguredForAllAssetsInClass(assets, directionProps);
};

export const shouldDeployToAllEdges = (edges: DeploymentEdges): boolean =>
  edges.indexOf(SpecialDeploymentKeys.ALL_EDGES) > -1;

export const isNoSyncStrategy = (assets: AssetConfig[]) => assets.length === 0;

export const getSyncStrategy = (
  selected: AssetConfig[], // note: this is only meant to accept configs for one asset_class
): SyncStrategies => {
  if (isNoSyncStrategy(selected)) {
    return SyncStrategies.NO_SYNC;
  } else if (shouldSyncAllAssetsForClass(selected, { sync_to_edge: true, sync_to_platform: true })) {
    return SyncStrategies.DEPLOY_AND_SYNC_BOTH;
  } else if (shouldSyncAllAssetsForClass(selected, { sync_to_edge: true })) {
    return SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_EDGE;
  } else if (shouldSyncAllAssetsForClass(selected, { sync_to_platform: true })) {
    return SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_PLATFORM;
  } else if (shouldDeployAllAssetsForClass(selected)) {
    return SyncStrategies.DEPLOY_TABLE;
  } else {
    return SyncStrategies.ITEM_LEVEL_SYNC;
  }
};

export const createAssetsFromSyncStrategy = (
  assetClass: string,
  selectedStrategy: SyncStrategies,
  existingStrategy: SyncStrategies,
): AssetConfig[] => {
  switch (selectedStrategy) {
    case SyncStrategies.DEPLOY_TABLE: {
      const deployStrategies = [
        SyncStrategies.DEPLOY_TABLE,
        SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_PLATFORM,
        SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_EDGE,
        SyncStrategies.DEPLOY_AND_SYNC_BOTH,
      ];
      const isAlreadyDeployed = deployStrategies.indexOf(existingStrategy) > -1;
      return isAlreadyDeployed
        ? []
        : [
            {
              asset_class: assetClass as keyof EntityModels, // tell the TS compiler we know what we're doing
              asset_id: SpecialDeploymentKeys.ALL_ASSETS_IN_CLASS,
              sync_to_edge: isAlreadyDeployed ? false : true,
              sync_to_platform: isAlreadyDeployed ? false : true,
            },
          ];
    }

    case SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_EDGE: {
      const toEdgeStrategies = [SyncStrategies.DEPLOY_AND_SYNC_BOTH, SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_EDGE];
      const isAlreadySyncedIdx = toEdgeStrategies.indexOf(existingStrategy);
      return [
        {
          asset_class: assetClass as keyof EntityModels, // tell the TS compiler we know what we're doing
          asset_id: SpecialDeploymentKeys.ALL_ASSETS_IN_CLASS,
          sync_to_edge: isAlreadySyncedIdx > -1 ? false : true,
          sync_to_platform:
            isAlreadySyncedIdx === 0 || existingStrategy === SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_PLATFORM
              ? true
              : false,
        },
      ];
    }
    case SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_PLATFORM: {
      const toPlatformStrategies = [
        SyncStrategies.DEPLOY_AND_SYNC_BOTH,
        SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_PLATFORM,
      ];
      const isAlreadySyncedIdx = toPlatformStrategies.indexOf(existingStrategy);
      return [
        {
          asset_class: assetClass as keyof EntityModels, // tell the TS compiler we know what we're doing
          asset_id: SpecialDeploymentKeys.ALL_ASSETS_IN_CLASS,
          sync_to_edge:
            isAlreadySyncedIdx === 0 || existingStrategy === SyncStrategies.DEPLOY_AND_SYNC_TABLE_TO_EDGE
              ? true
              : false,
          sync_to_platform: isAlreadySyncedIdx > -1 ? false : true,
        },
      ];
    }
    default:
      return [];
  }
};
