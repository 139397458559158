import React from 'react';

import { createText } from 'containers/Portal/pluginSettingUtils';

import { NATIVE_LIBRARIES } from '../../../serviceConstants';
import { AlertProvider } from '../../types';
import { SMSFields } from '../../../AlertTypes/sms/types';
import { ProviderCodeGenerator } from '../../../types';

interface MyProviderFields {
  apiKey: string;
  apiSecret: string;
}

class TwilioSMSProvider extends AlertProvider<SMSFields, MyProviderFields> {
  createProviderCode(ruleName: string, gen: ProviderCodeGenerator<SMSFields, MyProviderFields>) {
    return `
        var smsTo = ${gen.alertFields.smsTo};

        var options = {
            "auth": {
                user : "${gen.providerFields.apiKey}",
                pass : "${gen.providerFields.apiSecret}"
            },
            uri : "https://api.twilio.com/2010-04-01/Accounts/${gen.providerFields.apiKey}/SMS/Messages.json",
            "body": {
                "Body": "${gen.alertFields.smsBody}",
                "From": "${gen.alertFields.smsFrom}"
            },
            "form": true
        };

        var requestObject = Requests();

        function sendTextMessages (cb) {
          for (i=0, len = smsTo.length; i < len; i++) {
            options.body.To = smsTo[i];

            requestObject.post(options,function(err,result) {
                if(err) {
                    ${this.callNextAlertOrError(gen)}
                }
                if (i === len - 1) {
                  cb();
                }
            });
          }
        }

        sendTextMessages(function() {
          ${this.callNextAlertOrSuccess(gen)}
        })
        
    `;
  }

  getSummary(alertFields: SMSFields) {
    return (
      <div>
        Send SMS via <em>{this.label}</em> to <em>{alertFields.smsTo}</em>
      </div>
    );
  }
  getErrorCode() {
    return 'resp.error("Failed to send SMS; " + JSON.stringify(err));';
  }
  getSuccessCode() {
    return 'resp.success("Sent")';
  }
}

export default new TwilioSMSProvider(
  'twilio',
  'Twilio',
  [NATIVE_LIBRARIES.HTTP_LIBRARY],
  [
    {
      ...createText<MyProviderFields>('apiKey', 'API Key'),
      required: true,
    },
    {
      ...createText<MyProviderFields>('apiSecret', 'API Secret'),
      required: true,
    },
  ],
);
