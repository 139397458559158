/*
 *
 * Portal actions
 *
 */

import uuid from 'uuid/v4';

import { ActionConstants } from './actionConstants';
import { BaseAction } from 'cb-utils/baseAction';
import {
  WidgetContainers,
  MasterPortalConfig,
  PortalNotificationObject,
  ModalConfig,
  RGLBreakpointsExt,
} from './types';
import { PortalTheme } from './themes/types';
import { BackendPortalModel } from '../CbPortal/types';
import { DatasourceSettingsModalReturn } from 'components/DatasourceSettingsModal';
import { ExternalResourceWithPromise, DiffInfo, ExternalResource } from 'cb-utils/externalResourceUtils';
import WidgetModel, { WidgetInfo, WidgetSettingsReturn, WidgetSettingsInfo } from 'cb-utils/models/widget/WidgetModel';
import { ParserInstanceTypes } from 'utils/types';
import { PluginModel } from 'cb-utils/console-entity-models';
import { ClosePaneSettingsModalRtn } from 'components/PaneSettingsModal';
import { PageSettingsModalRtn } from 'components/PageSettingsModal';
import { DatasourceSettingsModalTabs } from 'components/DatasourceSettingsModal/utils';
import { InternalResource } from 'cb-utils/internalResourceUtils';
import { NotificationObject } from 'react-notification';
import { CbModalArgs, MODAL_COMPONENTS } from 'components/CbModal';

export interface InitPortalError extends BaseAction {
  type: ActionConstants.INIT_PORTAL_ERROR;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initPortalError(payload: any): InitPortalError {
  return {
    type: ActionConstants.INIT_PORTAL_ERROR,
    payload,
  };
}

interface AddPane extends BaseAction {
  type: ActionConstants.ADD_PANE;
  payload: {
    id: string;
    tabId: string;
    pageId: string;
  };
}

export function addPane(pageId: string, paneId: string, tabId: string): AddPane {
  return {
    type: ActionConstants.ADD_PANE,
    payload: {
      id: paneId,
      tabId,
      pageId,
    },
  };
}

interface AddWidgetToPaneLayout extends BaseAction {
  type: ActionConstants.ADD_WIDGET_TO_PANE_LAYOUT;
  payload: {
    pageId: string;
    id: string;
  };
}

export function addWidgetToPaneLayout(pageId: string, id: string): AddWidgetToPaneLayout {
  return {
    type: ActionConstants.ADD_WIDGET_TO_PANE_LAYOUT,
    payload: {
      pageId,
      id,
    },
  };
}

interface AddPaneTab extends BaseAction {
  type: ActionConstants.ADD_PANE_TAB;
  payload: {
    paneId: string;
    tabId: string;
  };
}

export function addPaneTab(paneId: string): AddPaneTab {
  return {
    type: ActionConstants.ADD_PANE_TAB,
    payload: {
      paneId,
      tabId: uuid(),
    },
  };
}

interface ChangeTab extends BaseAction {
  type: ActionConstants.CHANGE_TAB;
  payload: {
    tab: string;
    paneId: string;
  };
}

export function changeTab(tab: string, paneId: string): ChangeTab {
  return {
    type: ActionConstants.CHANGE_TAB,
    payload: {
      tab,
      paneId,
    },
  };
}

interface RenameTab {
  type: ActionConstants.RENAME_TAB;
  payload: {
    paneId: string;
    name: string;
    tabId: string;
  };
}

export function renameTab(paneId: string, name: string, tabId: string): RenameTab {
  return {
    type: ActionConstants.RENAME_TAB,
    payload: {
      paneId,
      name,
      tabId,
    },
  };
}

interface RemovePane extends BaseAction {
  type: ActionConstants.REMOVE_PANE;
  payload: {
    pageId: string;
    id: string;
    tabIds: string[];
  };
}

export function removePane(pageId: string, id: string, tabIds: string[]): RemovePane {
  return {
    type: ActionConstants.REMOVE_PANE,
    payload: {
      pageId,
      id,
      tabIds,
    },
  };
}

interface RemovePaneTab extends BaseAction {
  type: ActionConstants.REMOVE_PANE_TAB;
  payload: {
    tab: string;
    paneId: string;
  };
}

export function removePaneTab(tab: string, paneId: string): RemovePaneTab {
  return {
    type: ActionConstants.REMOVE_PANE_TAB,
    payload: {
      tab,
      paneId,
    },
  };
}

//
// export function updateWidgetSettings (data) {
//   return {
//     type:
//   }
// }

export interface AddDatasourceRequestConfirmed extends BaseAction {
  type: ActionConstants.ADD_DATASOURCE_REQUEST_CONFIRMED;
  payload: DatasourceSettingsModalReturn;
}

export function addDatasourceRequestConfirmed(info: DatasourceSettingsModalReturn): AddDatasourceRequestConfirmed {
  return {
    type: ActionConstants.ADD_DATASOURCE_REQUEST_CONFIRMED,
    payload: info,
  };
}

export function updateWidgetBeingMoved(payload: { widgetId?: string; fromTabId?: string }) {
  return {
    type: ActionConstants.UPDATE_WIDGET_BEING_MOVED as ActionConstants.UPDATE_WIDGET_BEING_MOVED,
    payload,
  };
}

type UpdateWidgetBeingMoved = ReturnType<typeof updateWidgetBeingMoved>;

interface AddDatasource extends BaseAction {
  type: ActionConstants.ADD_DATASOURCE;
  payload: {
    id: string;
  };
}

export function addDatasource(info: { id: string }): AddDatasource {
  return {
    type: ActionConstants.ADD_DATASOURCE,
    payload: {
      ...info,
    },
  };
}

interface DeleteDatasourceSuccess extends BaseAction {
  type: ActionConstants.DELETE_DATASOURCE_SUCCESS;
  payload: string;
}

export function deleteDatasourceSuccess(id: string): DeleteDatasourceSuccess {
  return {
    type: ActionConstants.DELETE_DATASOURCE_SUCCESS,
    payload: id,
  };
}

interface DeleteDatasource extends BaseAction {
  type: ActionConstants.DELETE_DATASOURCE;
  payload: {
    id: string;
  };
}

export function deleteDatasource(payload: { id: string }): DeleteDatasource {
  return {
    type: ActionConstants.DELETE_DATASOURCE,
    payload,
  };
}

export interface EditDatasourceRequest extends BaseAction {
  type: ActionConstants.EDIT_DATASOURCE_REQUEST;
  payload: {
    id: string;
    tab: DatasourceSettingsModalTabs;
  };
}

export function editDatasourceRequest(id: string, tab = DatasourceSettingsModalTabs.settings): EditDatasourceRequest {
  return {
    type: ActionConstants.EDIT_DATASOURCE_REQUEST,
    payload: {
      id,
      tab,
    },
  };
}

export interface EditDatasource extends BaseAction {
  type: ActionConstants.EDIT_DATASOURCE;
  payload: DatasourceSettingsModalReturn;
}

export function editDatasource(id: string, info: DatasourceSettingsModalReturn): EditDatasource {
  return {
    type: ActionConstants.EDIT_DATASOURCE,
    payload: {
      id,
      ...info,
    },
  };
}

interface DatasourcesAndWidgetsLoaded extends BaseAction {
  type: ActionConstants.DATASOURCES_AND_WIDGETS_LOADED;
  payload: {
    config: MasterPortalConfig;
  };
}

export function datasourcesAndWidgetsLoaded(data: { config: MasterPortalConfig }): DatasourcesAndWidgetsLoaded {
  return {
    type: ActionConstants.DATASOURCES_AND_WIDGETS_LOADED,
    payload: data,
  };
}

export interface EditWidgetRequestConfirmed extends BaseAction {
  type: ActionConstants.EDIT_WIDGET_REQUEST_CONFIRMED;
  payload: WidgetSettingsReturn;
}

export function editWidgetRequestConfirmed(data: WidgetSettingsReturn): EditWidgetRequestConfirmed {
  return {
    type: ActionConstants.EDIT_WIDGET_REQUEST_CONFIRMED,
    payload: {
      ...data,
    },
  };
}

export interface OpenWidgetEditorRequest extends BaseAction {
  type: ActionConstants.OPEN_WIDGET_EDITOR_REQUEST;
  payload: {
    id: string;
    openParser?: boolean;
    settingName?: string;
    parserType?: ParserInstanceTypes;
    selectedGroupItem?: string;
    selectedNestedGroupItem?: string;
  };
}

export interface OpenWidgetEditorRequestConfirmed extends BaseAction {
  type: ActionConstants.OPEN_WIDGET_EDITOR_REQUEST_CONFIRMED;
  payload: {
    id: string;
    openParser?: boolean;
    settingName?: string;
    parserType?: ParserInstanceTypes;
    selectedGroupItem?: string;
    selectedNestedGroupItem?: string;
    widgetInstance: WidgetModel<{}>;
  };
}

export function openWidgetEditorRequest(
  id: string,
  openParser?: boolean,
  settingName?: string,
  parserType?: ParserInstanceTypes,
  selectedGroupItem?: string,
  selectedNestedGroupItem?: string,
): OpenWidgetEditorRequest {
  return {
    type: ActionConstants.OPEN_WIDGET_EDITOR_REQUEST,
    payload: {
      id,
      openParser,
      settingName,
      parserType,
      selectedGroupItem,
      selectedNestedGroupItem,
    },
  };
}

export function openWidgetEditorRequestConfirmed(
  id: string,
  widgetInstance: WidgetModel<{}>,
  openParser?: boolean,
  settingName?: string,
  parserType?: ParserInstanceTypes,
  selectedGroupItem?: string,
  selectedNestedGroupItem?: string,
): OpenWidgetEditorRequestConfirmed {
  return {
    type: ActionConstants.OPEN_WIDGET_EDITOR_REQUEST_CONFIRMED,
    payload: {
      id,
      openParser,
      settingName,
      parserType,
      selectedGroupItem,
      selectedNestedGroupItem,
      widgetInstance,
    },
  };
}

export interface OpenWidgetSettingsPayload {
  target: WidgetContainers;
  id: string;
  widgetInfo: WidgetInfo<{}>;
  externalScripts: ExternalResourceWithPromise[];
  openParser: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openParserData: any;
  settingName?: string;
  selectedGroupItem?: string;
  selectedNestedGroupItem?: string;
}

export interface ConfirmActionPayload {
  confirmAction: string;
}

// confirm modal is kind of weird
// any time the confirm modal is opened, it should be passed a 'confirmAction' value
// that way the reducer knows what to do when the action is confirmed
export function confirmModalAction(payload: ConfirmActionPayload) {
  return {
    type: payload.confirmAction,
    payload,
  };
}

export interface AddWidgetRequest extends BaseAction {
  type: ActionConstants.ADD_WIDGET_REQUEST;
  payload: {
    paneId: string;
    tabId: string;
    target: WidgetContainers;
    modalName?: string;
  };
}

export function addWidgetRequest(
  paneId: string,
  tabId: string,
  target: WidgetContainers,
  modalName?: string,
): AddWidgetRequest {
  return {
    type: ActionConstants.ADD_WIDGET_REQUEST,
    payload: {
      paneId,
      tabId,
      target,
      modalName,
    },
  };
}

export interface AddWidgetRequestWithParamsPayload {
  paneId: string;
  tabId: string;
  target: WidgetContainers;
  externalScripts: ExternalResourceWithPromise[];
}

export interface AddWidgetRequestConfirmation extends BaseAction {
  type: ActionConstants.ADD_WIDGET_REQUEST_CONFIRMED;
  payload: {
    id: string;
    widgetInfo: WidgetSettingsInfo;
  };
}

export function addWidgetRequestConfirmation(
  tabId: string,
  widgetInfo: WidgetSettingsInfo,
  newWidgetId: string,
): AddWidgetRequestConfirmation {
  return {
    type: ActionConstants.ADD_WIDGET_REQUEST_CONFIRMED,
    payload: {
      id: newWidgetId,
      widgetInfo: {
        ...widgetInfo,
        tab: tabId,
        id: newWidgetId,
      },
    },
  };
}

export interface AddWidgetToCurrentContextLayout extends BaseAction {
  type: ActionConstants.ADD_WIDGET_TO_CURRENT_CONTEXT_LAYOUT;
  payload: {
    id: string;
    widgetInfo: WidgetSettingsInfo;
  };
}

export function addWidgetToCurrentContextLayout(
  tabId: string,
  widgetInfo: WidgetSettingsInfo,
  newWidgetId: string,
): AddWidgetToCurrentContextLayout {
  return {
    type: ActionConstants.ADD_WIDGET_TO_CURRENT_CONTEXT_LAYOUT,
    payload: {
      id: newWidgetId,
      widgetInfo: {
        ...widgetInfo,
        tab: tabId,
        id: newWidgetId,
      },
    },
  };
}

export interface AddWidget extends BaseAction {
  type: ActionConstants.ADD_WIDGET;
  payload: {
    id: string;
    widgetInfo: WidgetInfo<{}>;
  };
}

export function addWidget(tabId: string, widgetInfo: WidgetInfo<{}>, newWidgetId: string): AddWidget {
  return {
    type: ActionConstants.ADD_WIDGET,
    payload: {
      id: newWidgetId,
      widgetInfo: {
        ...widgetInfo,
        tab: tabId,
        id: newWidgetId,
      },
    },
  };
}

interface AddWidgetToPaneGridLayout extends BaseAction {
  type: ActionConstants.ADD_WIDGET_TO_PANE_GRID_LAYOUT;
  payload: {
    id: string;
    paneId: string;
    tabId: string;
    pageId: string;
  };
}

export function addWidgetToPaneGridLayout(
  paneId: string,
  tabId: string,
  pageId: string,
  newWidgetId: string,
): AddWidgetToPaneGridLayout {
  return {
    type: ActionConstants.ADD_WIDGET_TO_PANE_GRID_LAYOUT,
    payload: {
      id: newWidgetId,
      paneId,
      tabId,
      pageId,
    },
  };
}

export function moveWidgetBetweenPanes(payload: {
  paneId: string;
  toTabId: string;
  widgetId?: string;
  fromTabId?: string;
  pageId?: string;
}) {
  return {
    type: ActionConstants.MOVE_WIGET_BETWEEN_PANES as ActionConstants.MOVE_WIGET_BETWEEN_PANES,
    payload,
  };
}
export type MoveWidgetBetweenPanes = ReturnType<typeof moveWidgetBetweenPanes>;

interface AddWidgetToHeaderLayout extends BaseAction {
  type: ActionConstants.ADD_WIDGET_TO_HEADER_LAYOUT;
  payload: {
    id: string;
  };
}

export function addWidgetToHeaderLayout(newWidgetId: string): AddWidgetToHeaderLayout {
  return {
    type: ActionConstants.ADD_WIDGET_TO_HEADER_LAYOUT,
    payload: {
      id: newWidgetId,
    },
  };
}

export interface EditWidget extends BaseAction {
  type: ActionConstants.EDIT_WIDGET;
  payload: WidgetSettingsReturn;
}

export function editWidget(data: WidgetSettingsReturn): EditWidget {
  return {
    type: ActionConstants.EDIT_WIDGET,
    payload: {
      ...data,
    },
  };
}

interface CreateParserError extends BaseAction {
  type: ActionConstants.CREATE_PARSER_ERROR;
  payload: {
    id: string;
    type: string;
    message: string;
    settingName: string;
    parserType: ParserInstanceTypes;
  };
}

export function createParserError(
  id: string,
  type: string,
  message: string,
  settingName: string,
  parserType: ParserInstanceTypes,
): CreateParserError {
  return {
    type: ActionConstants.CREATE_PARSER_ERROR,
    payload: {
      id,
      type,
      message,
      settingName,
      parserType,
    },
  };
}

interface ExecuteParserError extends BaseAction {
  type: ActionConstants.EXECUTE_PARSER_ERROR;
  payload: {
    id: string;
    type: string;
    widgetName: string;
    message: string;
    settingName: string;
    parserType: ParserInstanceTypes;
  };
}

export function executeParserError(
  id: string,
  type: string,
  widgetName: string,
  message: string,
  settingName: string,
  parserType: ParserInstanceTypes,
): ExecuteParserError {
  return {
    type: ActionConstants.EXECUTE_PARSER_ERROR,
    payload: {
      id,
      type,
      widgetName,
      message,
      settingName,
      parserType,
    },
  };
}

interface CloseErrorModal extends BaseAction {
  type: ActionConstants.CLOSE_ERROR_MODAL;
  payload: {
    id: string;
    props: {
      acknowledged?: boolean;
    };
  };
}

export function closeErrorModal(id: string, props: { acknowledged?: boolean }): CloseErrorModal {
  return {
    type: ActionConstants.CLOSE_ERROR_MODAL,
    payload: {
      id,
      props,
    },
  };
}

interface SavePortalRequest extends BaseAction {
  type: ActionConstants.SAVE_PORTAL_REQUEST;
}

export function savePortalRequest(): SavePortalRequest {
  return {
    type: ActionConstants.SAVE_PORTAL_REQUEST,
  };
}

export interface BuiltConfigForSavePortalRequest extends BaseAction {
  type: ActionConstants.BUILT_CONFIG_FOR_SAVE_PORTAL_REQUEST;
  payload: {
    config: MasterPortalConfig;
  };
}

export function builtConfigForSavePortalRequest(config: MasterPortalConfig): BuiltConfigForSavePortalRequest {
  return {
    type: ActionConstants.BUILT_CONFIG_FOR_SAVE_PORTAL_REQUEST,
    payload: {
      config,
    },
  };
}

export interface LoadPortal extends BaseAction {
  type: ActionConstants.LOAD_PORTAL;
  payload: BackendPortalModel;
}

export function loadPortal(config: BackendPortalModel): LoadPortal {
  return {
    type: ActionConstants.LOAD_PORTAL,
    payload: config,
  };
}

interface SavePortalSuccess extends BaseAction {
  type: ActionConstants.SAVE_PORTAL_SUCCESS;
  payload: {
    name: string;
  };
}

export function savePortalSuccess(name: string): SavePortalSuccess {
  return {
    type: ActionConstants.SAVE_PORTAL_SUCCESS,
    payload: {
      name,
    },
  };
}

interface SavePortalError extends BaseAction {
  type: ActionConstants.SAVE_PORTAL_ERROR;
  payload: {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function savePortalError(name: string, err: any): SavePortalError {
  return {
    type: ActionConstants.SAVE_PORTAL_ERROR,
    payload: {
      name,
      err,
    },
  };
}

interface ToggleEditMode extends BaseAction {
  type: ActionConstants.TOGGLE_EDIT_MODE;
}

export function toggleEditMode(): ToggleEditMode {
  return {
    type: ActionConstants.TOGGLE_EDIT_MODE,
  };
}

export interface SetDirtyStatus extends BaseAction {
  type: ActionConstants.SET_DIRTY_STATUS;
  payload: {
    value: boolean;
    actionType: string;
  };
}

export function setDirtyStatus(bool: boolean, actionType = ''): SetDirtyStatus {
  return {
    type: ActionConstants.SET_DIRTY_STATUS,
    payload: {
      value: bool,
      actionType,
    },
  };
}

interface HideModal extends BaseAction {
  type: ActionConstants.HIDE_MODAL;
}

export function hideModal() {
  return {
    type: ActionConstants.HIDE_MODAL,
  };
}

export interface UpdatePaneSettings extends BaseAction {
  type: ActionConstants.UPDATE_PANE_SETTINGS;
  payload: ClosePaneSettingsModalRtn;
}

export function updatePaneSettings(data: ClosePaneSettingsModalRtn): UpdatePaneSettings {
  return {
    type: ActionConstants.UPDATE_PANE_SETTINGS,
    payload: data,
  };
}

interface DatasourceDataUpdated extends BaseAction {
  type: ActionConstants.DATASOURCE_DATA_UPDATED;
  payload: {
    id: string;
    lastUpdated: number;
  };
}

export function datasourceDataUpdated(id: string, lastUpdated: number): DatasourceDataUpdated {
  return {
    type: ActionConstants.DATASOURCE_DATA_UPDATED,
    payload: {
      id,
      lastUpdated,
    },
  };
}

export interface WidgetUpdated extends BaseAction {
  type: ActionConstants.WIDGET_UPDATED;
  payload: {
    id: string;
    lastUpdated: number;
  };
}

export function widgetUpdated(id: string, lastUpdated: number): WidgetUpdated {
  return {
    type: ActionConstants.WIDGET_UPDATED,
    payload: {
      id,
      lastUpdated,
    },
  };
}

interface DismissAlert extends BaseAction {
  type: ActionConstants.ALERT_DISMISSED;
  payload: {
    alert: PortalNotificationObject;
  };
}

export function dismissAlert(alert: PortalNotificationObject): DismissAlert {
  return {
    type: ActionConstants.ALERT_DISMISSED,
    payload: {
      alert,
    },
  };
}

interface DismissErrorAlert extends BaseAction {
  type: ActionConstants.ERROR_ALERT_DISMISSED;
  payload: {
    alert: PortalNotificationObject;
  };
}

export function dismissErrorAlert(alert: PortalNotificationObject): DismissErrorAlert {
  return {
    type: ActionConstants.ERROR_ALERT_DISMISSED,
    payload: {
      alert,
    },
  };
}

export interface ErrorAlertClicked extends BaseAction {
  type: ActionConstants.ERROR_ALERT_CLICKED;
  payload: {
    alert: PortalNotificationObject;
  };
}

export function errorAlertClicked(alert: PortalNotificationObject): ErrorAlertClicked {
  return {
    type: ActionConstants.ERROR_ALERT_CLICKED,
    payload: {
      alert,
    },
  };
}

interface ViewLatestDataForDatasource extends BaseAction {
  type: ActionConstants.VIEW_LATEST_DATA_FOR_DATASOURCE;
  payload: {
    id: string;
  };
}

export function viewLatestDataForDatasource(id: string): ViewLatestDataForDatasource {
  return {
    type: ActionConstants.VIEW_LATEST_DATA_FOR_DATASOURCE,
    payload: {
      id,
    },
  };
}

interface HandleDatasourceError extends BaseAction {
  type: ActionConstants.HANDLE_DATASOURCE_ERROR;
  payload: {
    id: string;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    collapsedText?: string;
  };
}

export function handleDatasourceError(payload: HandleDatasourceError['payload']): HandleDatasourceError {
  return {
    type: ActionConstants.HANDLE_DATASOURCE_ERROR,
    payload,
  };
}

interface CreateErrorModal extends BaseAction {
  type: ActionConstants.CREATE_ERROR_MODAL;
  payload: {
    id: string;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    collapsedText?: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createErrorModal(payload: { id: string; title: string; error: any }): CreateErrorModal {
  return {
    type: ActionConstants.CREATE_ERROR_MODAL,
    payload,
  };
}

interface UserLogOutRequest extends BaseAction {
  type: ActionConstants.USER_LOGOUT_REQUEST;
}

export function userLogOutRequest(): UserLogOutRequest {
  return {
    type: ActionConstants.USER_LOGOUT_REQUEST,
  };
}

export interface UserLogOutRequestConfirmed extends BaseAction {
  type: ActionConstants.USER_LOGOUT_REQUEST_CONFIRMED;
}

export function userLogOutRequestConfirmed(): UserLogOutRequestConfirmed {
  return {
    type: ActionConstants.USER_LOGOUT_REQUEST_CONFIRMED,
  };
}

interface UserLogOutSuccess extends BaseAction {
  type: ActionConstants.USER_LOGOUT_SUCCESS;
}

export function userLogOutSuccess() {
  return {
    type: ActionConstants.USER_LOGOUT_SUCCESS,
  };
}

interface UserLogOutError extends BaseAction {
  type: ActionConstants.USER_LOGOUT_ERROR;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function userLogOutError(error: any): UserLogOutError {
  return {
    type: ActionConstants.USER_LOGOUT_ERROR,
    payload: {
      error,
    },
  };
}

interface ToggleStyleMenu extends BaseAction {
  type: ActionConstants.TOGGLE_STYLE_MENU;
}

export function toggleStyleMenu(): ToggleStyleMenu {
  return {
    type: ActionConstants.TOGGLE_STYLE_MENU,
  };
}

interface ToggleFlyoutPane extends BaseAction {
  type: ActionConstants.TOGGLE_FLYOUT_PANE;
}

export function toggleFlyoutPane(): ToggleFlyoutPane {
  return {
    type: ActionConstants.TOGGLE_FLYOUT_PANE,
  };
}

interface SetupDefaulHeadertLayout extends BaseAction {
  type: ActionConstants.SETUP_DEFAULT_HEADER_LAYOUT;
}

export function setupDefaulHeadertLayout(): SetupDefaulHeadertLayout {
  return {
    type: ActionConstants.SETUP_DEFAULT_HEADER_LAYOUT,
  };
}

export interface GoFullScreenRequest extends BaseAction {
  type: ActionConstants.GO_FULL_SCREEN_REQUEST;
  payload: {
    id: string;
  };
}

export function goFullScreenRequest(id: string): GoFullScreenRequest {
  return {
    type: ActionConstants.GO_FULL_SCREEN_REQUEST,
    payload: {
      id,
    },
  };
}

export interface RefreshDatasource extends BaseAction {
  type: ActionConstants.REFRESH_DATASOURCE;
  payload: {
    id: string;
  };
}

export function refreshDatasource(id: string): RefreshDatasource {
  return {
    type: ActionConstants.REFRESH_DATASOURCE,
    payload: {
      id,
    },
  };
}

interface HandleSimulatedScreenSizeChange extends BaseAction {
  type: ActionConstants.HANDLE_SIMULATED_SCREEN_SIZE_CHANGE;
  payload: {
    breakpoint: RGLBreakpointsExt;
  };
}

export function handleSimulatedScreenSizeChange(breakpoint: RGLBreakpointsExt): HandleSimulatedScreenSizeChange {
  return {
    type: ActionConstants.HANDLE_SIMULATED_SCREEN_SIZE_CHANGE,
    payload: {
      breakpoint,
    },
  };
}

export interface AddPage extends BaseAction {
  type: ActionConstants.ADD_PAGE;
  payload: {
    id: string;
    name: string;
    parentId?: string;
    isParam: boolean;
    defaultValue: string;
  };
}

export interface AddPageInfo {
  name: string;
  parentId: string;
  isParam: boolean;
  defaultValue: string;
}

export function addPage({ name, parentId, isParam, defaultValue }: AddPageInfo): AddPage {
  return {
    type: ActionConstants.ADD_PAGE,
    payload: {
      id: uuid(),
      name,
      parentId,
      isParam,
      defaultValue,
    },
  };
}

interface EditPage extends BaseAction {
  type: ActionConstants.EDIT_PAGE;
  payload: PageSettingsModalRtn;
}

export function editPage(id: string, data: PageSettingsModalRtn): EditPage {
  return {
    type: ActionConstants.EDIT_PAGE,
    payload: {
      ...data,
      id,
    },
  };
}

interface DeletePageAndChildren extends BaseAction {
  type: ActionConstants.DELETE_PAGE_AND_CHILDREN;
  payload: {
    ids: string[];
  };
}

export function deletePageAndChildren(ids: string[]): DeletePageAndChildren {
  return {
    type: ActionConstants.DELETE_PAGE_AND_CHILDREN,
    payload: {
      ids,
    },
  };
}

interface SelectPageById extends BaseAction {
  type: ActionConstants.SELECT_PAGE_BY_ID;
  payload: {
    id: string;
  };
}

export function selectPageById(id: string): SelectPageById {
  return {
    type: ActionConstants.SELECT_PAGE_BY_ID,
    payload: {
      id,
    },
  };
}

export interface SelectPageWhileInDevMode extends BaseAction {
  type: ActionConstants.SELECT_PAGE_WHILE_IN_DEV_MODE;
  payload: {
    id: string;
    path: string;
  };
}

export function selectPageWhileInDevMode(id: string, path: string): SelectPageWhileInDevMode {
  return {
    type: ActionConstants.SELECT_PAGE_WHILE_IN_DEV_MODE,
    payload: {
      id,
      path,
    },
  };
}

export interface SelectPageByPathRequest extends BaseAction {
  type: ActionConstants.SELECT_PAGE_BY_PATH_REQUEST;
  payload: {
    path: string;
  };
}

// only used by CB_PORTAL.selectPage()
export function selectPageByPathRequest(path: string): SelectPageByPathRequest {
  return {
    type: ActionConstants.SELECT_PAGE_BY_PATH_REQUEST,
    payload: {
      path,
    },
  };
}

export type OpenUserModal = ReturnType<typeof openUserModal>;
export function openUserModal(payload: { modalName: string }) {
  return {
    type: ActionConstants.OPEN_USER_MODAL as ActionConstants.OPEN_USER_MODAL,
    payload,
  };
}

type CloseUserModal = ReturnType<typeof closeUserModal>;
export function closeUserModal(payload: { modalName?: string }) {
  return {
    type: ActionConstants.CLOSE_USER_MODAL as ActionConstants.CLOSE_USER_MODAL,
    payload,
  };
}

export type AddUserModal = ReturnType<typeof addUserModal>;
export function addUserModal(payload: ModalConfig) {
  return {
    type: ActionConstants.ADD_USER_MODAL as ActionConstants.ADD_USER_MODAL,
    payload,
  };
}

export type AddDefaultUserModal = ReturnType<typeof addDefaultUserModal>;
export function addDefaultUserModal(payload: ModalConfig) {
  return {
    type: ActionConstants.ADD_DEFAULT_USER_MODAL as ActionConstants.ADD_DEFAULT_USER_MODAL,
    payload,
  };
}

export type ResetDefaultUserModal = ReturnType<typeof resetDefaultUserModal>;
export function resetDefaultUserModal() {
  return {
    type: ActionConstants.RESET_DEFAULT_USER_MODAL as ActionConstants.RESET_DEFAULT_USER_MODAL,
  };
}

type EditUserModal = ReturnType<typeof editUserModal>;
export function editUserModal(payload: ModalConfig) {
  return {
    type: ActionConstants.EDIT_USER_MODAL as ActionConstants.EDIT_USER_MODAL,
    payload,
  };
}

export type DeleteUserModal = ReturnType<typeof deleteUserModal>;
export function deleteUserModal(payload: Pick<ModalConfig, 'name'>) {
  return {
    type: ActionConstants.DELETE_USER_MODAL as ActionConstants.DELETE_USER_MODAL,
    payload,
  };
}

interface ChangeTheme extends BaseAction {
  type: ActionConstants.CHANGE_THEME;
  payload: {
    theme: PortalTheme;
  };
}

export function changeTheme(theme: PortalTheme): ChangeTheme {
  return {
    type: ActionConstants.CHANGE_THEME,
    payload: {
      theme,
    },
  };
}

interface ManageExternalScripts extends BaseAction {
  type: ActionConstants.MANAGE_EXTERNAL_SCRIPTS;
  payload: {
    externalScripts: ExternalResourceWithPromise[];
  };
}

export function manageExternalScripts(externalScripts: ExternalResourceWithPromise[]): ManageExternalScripts {
  return {
    type: ActionConstants.MANAGE_EXTERNAL_SCRIPTS,
    payload: {
      externalScripts,
    },
  };
}

interface ManageExternalScriptsRequest extends BaseAction {
  type: ActionConstants.MANAGE_EXTERNAL_SCRIPTS_REQUEST;
}

export function manageExternalScriptsRequest(): ManageExternalScriptsRequest {
  return {
    type: ActionConstants.MANAGE_EXTERNAL_SCRIPTS_REQUEST,
  };
}

export interface UpdateExternalScripts extends BaseAction {
  type: ActionConstants.UPDATE_EXTERNAL_SCRIPTS;
  payload: {
    scripts: ExternalResource[];
    diff?: DiffInfo;
  };
}

export function updateExternalScripts(scripts: ExternalResource[], diff?: DiffInfo): UpdateExternalScripts {
  return {
    type: ActionConstants.UPDATE_EXTERNAL_SCRIPTS,
    payload: {
      scripts,
      diff,
    },
  };
}

export interface UpdatedExternalScripts extends BaseAction {
  type: ActionConstants.UPDATED_EXTERNAL_SCRIPTS;
}

export function updatedExternalScripts(): UpdatedExternalScripts {
  return {
    type: ActionConstants.UPDATED_EXTERNAL_SCRIPTS,
  };
}

export interface FetchPortalError extends BaseAction {
  type: ActionConstants.FETCH_PORTAL_ERROR;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchPortalError(err: any): FetchPortalError {
  return {
    type: ActionConstants.FETCH_PORTAL_ERROR,
    payload: err,
  };
}

export function injectPlugins(plugins: PluginModel[]) {
  const head = document.getElementsByTagName('head')[0];
  plugins.forEach(plugin => {
    const pluginTag = document.createElement('script');
    pluginTag.onerror = () => {
      alert(`Failed to load plugin '${plugin.name}' from URL: '${plugin.url}'`);
    };
    pluginTag.type = 'text/javascript';
    pluginTag.src = plugin.url;
    head.appendChild(pluginTag);
  });
}

interface AddSuccessNotification extends BaseAction {
  type: ActionConstants.ADD_SUCCESS_NOTIFICATION;
  payload: Partial<NotificationObject>;
}

export const addSuccessNotification = (notification: Partial<NotificationObject>): AddSuccessNotification => ({
  type: ActionConstants.ADD_SUCCESS_NOTIFICATION,
  payload: notification,
});

interface AddErrorNotification extends BaseAction {
  type: ActionConstants.ADD_ERROR_NOTIFICATION;
  payload: Partial<NotificationObject>;
}

export const addErrorNotification = (notification: Partial<NotificationObject>): AddErrorNotification => ({
  type: ActionConstants.ADD_ERROR_NOTIFICATION,
  payload: notification,
});

export interface RemoveWidgetsInPane extends BaseAction {
  type: ActionConstants.REMOVE_PANE_REQUEST_CONFIRMED;
  payload: {
    id: string;
    pageId: string;
    tabIds: string[];
  };
}

export interface RemoveWidgetsInPaneTab extends BaseAction {
  type: ActionConstants.REMOVE_PANE_TAB_REQUEST_CONFIRMED;
  payload: {
    paneId: string;
    tab: string;
  };
}

interface CleanUpStuff extends BaseAction {
  type: ActionConstants.CLEAN_UP_STUFF;
}

export const cleanUpStuff = (): CleanUpStuff => ({
  type: ActionConstants.CLEAN_UP_STUFF,
});

interface SetDevConsoleOpenState extends BaseAction {
  type: ActionConstants.SET_DEV_CONSOLE_OPEN_STATE;
  payload: {
    open: boolean;
  };
}

export const createSetDevConsoleOpenState = (open: boolean): SetDevConsoleOpenState => ({
  type: ActionConstants.SET_DEV_CONSOLE_OPEN_STATE,
  payload: {
    open,
  },
});

export interface SubmitInternalResource extends BaseAction {
  type: ActionConstants.SUBMIT_INTERNAL_RESOURCE;
  payload: InternalResource;
}

export const submitInternalResource = (file: InternalResource): SubmitInternalResource => ({
  type: ActionConstants.SUBMIT_INTERNAL_RESOURCE,
  payload: file,
});

export interface DeleteInternalResource extends BaseAction {
  type: ActionConstants.DELETE_INTERNAL_RESOURCE;
  payload: {
    id: string;
  };
}

export const deleteInternalResource = (id: string): DeleteInternalResource => ({
  type: ActionConstants.DELETE_INTERNAL_RESOURCE,
  payload: {
    id,
  },
});

export interface UpdatedInternalResources extends BaseAction {
  type: ActionConstants.UPDATED_INTERNAL_RESOURCES;
}

export const updatedInternalResources = () => ({
  type: ActionConstants.UPDATED_INTERNAL_RESOURCES,
});

export interface ReorderInternalResources extends BaseAction {
  type: ActionConstants.REORDER_INTERNAL_RESOURCES;
  payload: string[];
}

export const reorderInternalResources = (order: string[]): ReorderInternalResources => ({
  type: ActionConstants.REORDER_INTERNAL_RESOURCES,
  payload: order,
});

export interface ShowBaseModal extends BaseAction {
  type: ActionConstants.SHOW_BASE_MODAL;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: CbModalArgs<any>;
}

export function showBaseModal<MT extends keyof typeof MODAL_COMPONENTS>(modalInfo: CbModalArgs<MT>) {
  return {
    type: ActionConstants.SHOW_BASE_MODAL,
    payload: modalInfo,
  };
}

export interface EnableHotReloadRequest extends BaseAction {
  type: ActionConstants.ENABLE_HOT_RELOAD_REQUEST;
}

export const enableHotReload = () => {
  return {
    type: ActionConstants.ENABLE_HOT_RELOAD_REQUEST,
  };
};
export interface EnableHotReloadSuccess extends BaseAction {
  type: ActionConstants.ENABLE_HOT_RELOAD_SUCCESS;
}

export const enableHotReloadSuccess = () => {
  return {
    type: ActionConstants.ENABLE_HOT_RELOAD_SUCCESS,
  };
};

export interface DisableHotReloadRequest extends BaseAction {
  type: ActionConstants.DISABLE_HOT_RELOAD_REQUEST;
}

export const disableHotReload = () => {
  return {
    type: ActionConstants.DISABLE_HOT_RELOAD_REQUEST,
  };
};

export interface DisableHotReloadSuccess extends BaseAction {
  type: ActionConstants.DISABLE_HOT_RELOAD_SUCCESS;
}

export const disableHotReloadSuccess = () => {
  return {
    type: ActionConstants.DISABLE_HOT_RELOAD_SUCCESS,
  };
};

export interface ToggleQuickAddOpen extends BaseAction {
  type: ActionConstants.TOGGLE_QUICK_ADD_OPEN;
  payload: {
    open: boolean;
  };
}

export const createToggleQuickAddOpen = ({ open }: { open: boolean }) => ({
  type: ActionConstants.TOGGLE_QUICK_ADD_OPEN,
  payload: {
    open,
  },
});

export type ActionTypes =
  | InitPortalError
  | AddPane
  | AddPaneTab
  | ChangeTab
  | RenameTab
  | RemovePane
  | RemovePaneTab
  | AddDatasourceRequestConfirmed
  | AddDatasource
  | DeleteDatasourceSuccess
  | EditDatasourceRequest
  | EditDatasource
  | DatasourcesAndWidgetsLoaded
  | EditWidgetRequestConfirmed
  | OpenWidgetEditorRequest
  | AddWidgetRequest
  | AddWidgetRequestConfirmation
  | AddWidget
  | AddWidgetToPaneGridLayout
  | AddWidgetToHeaderLayout
  | EditWidget
  | CreateParserError
  | ExecuteParserError
  | CloseErrorModal
  | SavePortalRequest
  | BuiltConfigForSavePortalRequest
  | LoadPortal
  | SavePortalSuccess
  | SavePortalError
  | ToggleEditMode
  | SetDirtyStatus
  | HideModal
  | UpdatePaneSettings
  | DatasourceDataUpdated
  | WidgetUpdated
  | DismissAlert
  | ErrorAlertClicked
  | ViewLatestDataForDatasource
  | HandleDatasourceError
  | CreateErrorModal
  | UserLogOutRequest
  | UserLogOutRequestConfirmed
  | UserLogOutSuccess
  | UserLogOutError
  | ToggleStyleMenu
  | ToggleFlyoutPane
  | SetupDefaulHeadertLayout
  | GoFullScreenRequest
  | RefreshDatasource
  | AddPage
  | EditPage
  | DeletePageAndChildren
  | SelectPageById
  | SelectPageWhileInDevMode
  | SelectPageByPathRequest
  | ChangeTheme
  | ManageExternalScripts
  | ManageExternalScriptsRequest
  | UpdateExternalScripts
  | UpdatedExternalScripts
  | FetchPortalError
  | AddSuccessNotification
  | AddErrorNotification
  | DismissErrorAlert
  | HandleSimulatedScreenSizeChange
  | CleanUpStuff
  | SetDevConsoleOpenState
  | AddUserModal
  | AddDefaultUserModal
  | EditUserModal
  | CloseUserModal
  | OpenUserModal
  | DeleteUserModal
  | SubmitInternalResource
  | DeleteInternalResource
  | ReorderInternalResources
  | UpdatedInternalResources
  | UpdateWidgetBeingMoved
  | MoveWidgetBetweenPanes
  | ShowBaseModal
  | EnableHotReloadRequest
  | EnableHotReloadSuccess
  | DisableHotReloadRequest
  | DisableHotReloadSuccess
  | AddWidgetToPaneLayout
  | OpenWidgetEditorRequestConfirmed
  | ToggleQuickAddOpen;
