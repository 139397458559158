import React from 'react';
import { createSummaryForUserEvent, UserConditionOptions, UserRequestParser } from './utils';
import { TriggerSourceDefinition } from '../types';
import { UserOptions } from 'cb-utils/console-entity-models';

const UserUpdated: TriggerSourceDefinition = {
  name: UserOptions.UserUpdated,
  getSummary: defKeyObject => <div>{createSummaryForUserEvent(defKeyObject, 'Updated')}</div>,
  requestParser: UserRequestParser,
  conditionOptions: [UserConditionOptions[0]],
};

export default UserUpdated;
