import {
  RoleModel,
  PermissionPrimitive,
  CollectionPermissionModel,
  BasicListPermissionModel,
  Permissions,
  canPerformOperation,
  PermissionsModel,
  SimplePermSections,
  AllPermCodes,
} from './roles';
import produce from 'immer';

export const overallAssetToPermSection = {
  users: 'UsersList' as 'UsersList',
  manageusers: 'ManageUsers' as 'ManageUsers',
  devices: 'DevicesList' as 'DevicesList',
  collections: 'AllCollections' as 'AllCollections',
  messages: 'MsgHistory' as 'MsgHistory',
  services: 'AllServices' as 'AllServices',
  deployments: 'Deployments' as 'Deployments',
  triggers: 'Triggers' as 'Triggers',
  timers: 'Timers' as 'Timers',
  edges: 'EdgesList' as 'EdgesList',
  roles: 'Roles' as 'Roles',
  externaldatabases: 'AllExternalDatabases' as 'AllExternalDatabases',
};

export const detailAssetToPermSection = {
  collections: 'Collections' as 'Collections',
  services: 'CodeServices' as 'CodeServices',
  portals: 'Portals' as 'Portals',
  messages: 'Topics' as 'Topics',
  serviceCaches: 'ServiceCaches' as 'ServiceCaches',
  externaldatabases: 'ExternalDatabases' as 'ExternalDatabases',
};

export type AssetClassType = keyof typeof detailAssetToPermSection | keyof typeof overallAssetToPermSection;

export function assetClassToPermissionName(assetClass: AssetClassType, hasItem = false) {
  if (hasItem) {
    return detailAssetToPermSection[assetClass as keyof typeof detailAssetToPermSection];
  } else {
    return overallAssetToPermSection[assetClass as keyof typeof overallAssetToPermSection];
  }
}

// pull the specific asset or item out of a role
// ex. get the permissions for collection "colors" from role "Administrator"
// getOnePermFromRole(Administrator, 'collections', 'colors') = { Level: 1 }
export function getOnePermFromRole(role: RoleModel, assetClass: AssetClassType, itemName?: string) {
  const permSection = assetClassToPermissionName(assetClass, !!itemName);
  let perms: PermissionPrimitive;
  const section = role.Permissions[permSection];
  if (!Array.isArray(section)) {
    perms = section;
  } else {
    perms = (section as BasicListPermissionModel[]).find(perm => perm.Name === itemName) || {
      Level: undefined,
    };
  }
  return perms;
}

export function getShowPermissions(allPermissionCode: number) {
  const showRead = canPerformOperation(allPermissionCode, Permissions.Read);
  const showCreate = canPerformOperation(allPermissionCode, Permissions.Create);
  const showUpdate = canPerformOperation(allPermissionCode, Permissions.Update);
  const showDelete = canPerformOperation(allPermissionCode, Permissions.Delete);
  return { showRead, showCreate, showUpdate, showDelete };
}

// this is for a top level check mark for an array of roles
// calculates the which have all checked, ex. if one role is missing create it is unchecked
export function getPermissionsAllHave(roles: RoleModel[], assetClass: AssetClassType, itemName?: string) {
  let read = Permissions.Read;
  let create = Permissions.Create;
  let update = Permissions.Update;
  let deletee = Permissions.Delete;
  roles.forEach(role => {
    const perm = getOnePermFromRole(role, assetClass, itemName);
    if (!canPerformOperation(perm.Level, Permissions.Read)) {
      // if anyone role doesn't have this permission it is removed
      read = 0;
    }
    if (!canPerformOperation(perm.Level, Permissions.Create)) {
      create = 0;
    }
    if (!canPerformOperation(perm.Level, Permissions.Update)) {
      update = 0;
    }
    if (!canPerformOperation(perm.Level, Permissions.Delete)) {
      deletee = 0;
    }
  });
  return read + create + update + deletee;
}

// Moved from roles reducer
const determineLevel = (current: number, level: number, allPermissionCode = Permissions.All) => {
  const formatted = current + level;
  return formatted < 0 ? 0 : formatted > allPermissionCode ? allPermissionCode : formatted;
};

function isBasicList(key: keyof PermissionsModel) {
  return (
    key === 'Portals' ||
    key === 'CodeServices' ||
    key === 'Topics' ||
    key === 'ServiceCaches' ||
    key === 'ExternalDatabases'
  );
}

export const getAllPermsCode = (key: string) => {
  return AllPermCodes[key as keyof typeof AllPermCodes] || +Permissions.All;
};

const modifyPermissionForEntity = (
  perm: CollectionPermissionModel | BasicListPermissionModel,
  idKey: keyof CollectionPermissionModel | keyof BasicListPermissionModel,
  assetId: string,
  level: number,
  allPermissionCode?: number,
): boolean => {
  const found = perm[idKey] === assetId;
  if (
    found &&
    !canPerformOperation(perm.Level, level) // no need to tweak anything if the permission is already applied; really only applicable when users are toggling the 'All' checkboxes
  ) {
    perm.Level = determineLevel(perm.Level, level, allPermissionCode);
  }
  return found;
};

// modifyListPermissions either modifies an existing permission in place or adds a new asset entry to the list of permissions
const modifyListPermissions = (
  // tslint:disable-next-line
  perms: Array<CollectionPermissionModel | BasicListPermissionModel>,
  idKey: keyof CollectionPermissionModel | keyof BasicListPermissionModel,
  level: number,
  assetId: string,
  assetName: string,
  allPermissionCode?: number,
) => {
  let found = false;
  for (let i = 0, len = perms.length; i < len; i += 1) {
    found = modifyPermissionForEntity(perms[i], idKey, assetId, level, allPermissionCode);
    if (found) {
      return;
    }
  }
  if (!found) {
    perms.push({
      [idKey]: assetId,
      Name: assetName,
      Level: determineLevel(0, level, allPermissionCode),
    });
  }
};

export function changePermission(
  role: RoleModel,
  permSection: keyof PermissionsModel,
  level: number,
  assets: Array<{ name: string; collectionID?: string }>,
): RoleModel;

export function changePermission(role: RoleModel, permSection: keyof SimplePermSections, level: number): RoleModel;

// handles updating a role when user clicks a permission checkbox
// returns a new role with the changes
export function changePermission(
  role: RoleModel,
  permSection: keyof PermissionsModel,
  level: number,
  assets?: Array<{ name: string; collectionID?: string; Name?: string }>,
) {
  return produce(role, newRole => {
    if (permSection === 'Collections') {
      for (const asset of assets) {
        modifyListPermissions(newRole.Permissions.Collections, 'ID', level, asset.collectionID, asset.name);
      }
      return;
    } else if (isBasicList(permSection)) {
      for (const asset of assets) {
        // topics has Name cause it's faked from Roles
        // shouldnt allow '', but for now needs to be accepted or undefined gets in and can't be deleted
        const name = asset.name === undefined ? asset.Name : asset.name;

        modifyListPermissions(
          newRole.Permissions[permSection] as BasicListPermissionModel[],
          'Name',
          level,
          name, // others don't have a id but still need something
          name,
          getAllPermsCode(permSection),
        );
      }
      return;
    } else {
      (newRole.Permissions[permSection] as PermissionPrimitive).Level = determineLevel(
        (newRole.Permissions[permSection] as PermissionPrimitive).Level,
        level,
      );
      return;
    }
  });
}
