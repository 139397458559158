import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import uuid from 'uuid/v4';
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux';
import getInjectors from './sagaInjectors';
import { AnyMap } from 'cb-utils/console-entity-models';
import { AnyAction } from 'redux';
import { ConfiguredStore } from 'configureStore';
export interface SagaDescriptor extends AnyMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saga: () => Iterator<any>;
  mode?: string;
}

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.DAEMON) the saga will be started
 * on component mount and never canceled or started again. Another two options:
 *   - constants.RESTART_ON_REMOUNT — the saga will be started on component mount and
 *   cancelled with `task.cancel()` on component unmount for improved performance,
 *   - constants.ONCE_TILL_UNMOUNT — behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default ({
  key,
  saga,
  mode = '',
}: {
  key: string;
} & SagaDescriptor) => (WrappedComponent: React.ComponentType) => {
  class InjectSaga extends React.Component {
    static WrappedComponent = WrappedComponent;

    static contextType = ReactReduxContext;

    static displayName = `withSaga(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    injectors: ReturnType<typeof getInjectors> = null;
    id = uuid(); // we create a unique id for each instance of the component so that ejectSaga will clean up after itself rather than other instances that use the same key. refer to https://clearblade.atlassian.net/browse/CBUI-444 for more info
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any, context: ReactReduxContextValue<any, AnyAction>) {
      super(props, context);

      this.injectors = getInjectors(context.store as ConfiguredStore<{}>);

      this.injectors.injectSaga(key, { saga, mode }, this.props, this.id);
    }

    componentWillUnmount() {
      this.injectors.ejectSaga(key, this.id);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};

const useInjectSaga = ({
  key,
  saga,
  mode,
}: {
  key: string;
} & SagaDescriptor) => () => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    const injectors = getInjectors(context.store as ConfiguredStore<{}>);
    const id = uuid();
    injectors.injectSaga(key, { saga, mode }, {}, id);

    return () => {
      injectors.ejectSaga(key, id);
    };
  }, []);
};

export { useInjectSaga };
