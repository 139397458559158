import brandLogoUrl from 'img/brand-logos/clearblade_black.png';
import brandLogoWhiteUrl from 'img/brand-logos/clearblade_white.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import vars from './bootstrapVars.scss';

// this needs to be MANUALLY checked and kept up to date with scss file
interface BootstrapVars {
  primary: string;
  lightPrimary: string;
  darkPrimary: string;
  secondary: string;
  success: string;
  danger: string;
  logo_color: string;
  bannerImg: string;
  angle_down_icon: string;

  white: string;
  gray100: string;
  gray200: string;
  gray300: string;
  gray400: string;
  gray500: string;
  gray600: string;
  gray700: string;
  gray800: string;
  gray900: string;
  black: string;

  header_background: string;
  header_text_color: string;

  landing_page_landingPageHeaderBackground: string;
  landing_page_landingPageHeaderTextColor: string;
  landing_page_loginButton: string;
  landing_page_loginButtonFontColor: string;
  landing_page_mainContainerFontColor: string;
  landing_page_registrationFormWrapper: string;
  landing_page_registrationFormHeaderFontColor: string;
  landing_page_submitButton: string;
  landing_page_submitButtonFontColor: string;
  landing_page_platformOverviewContainer: string;
  landing_page_platformOverviewFontColor: string;
  landing_page_platformOverviewServicesFontColor: string;
  landing_page_platformOverviewOutlineButton: string;
  landing_page_platformOverviewOutlineButtonBorder: string;
  landing_page_platformOverviewOutlineButtonFontColor: string;
  landing_page_platformOverviewButton: string;
  landing_page_platformOverviewButtonFontColor: string;
  landing_page_ipmOverviewContainer: string;
  landing_page_ipmOverviewButton: string;
  landing_page_ipmOverviewButtonFontColor: string;
  landing_page_developerResourcesContainer: string;
  landing_page_developerResourcesFontColor: string;
  landing_page_developerResourcesServicesFontColor: string;
  landing_page_developerResourcesButton: string;
  landing_page_developerResourcesButtonFontColor: string;
}

export default {
  ...(vars as BootstrapVars),
  brandLogoUrl: brandLogoUrl as string,
  // to be displayed on black background, can often be the same if logo isn't black
  brandLogoWhiteUrl: brandLogoWhiteUrl as string,
  productName: 'ClearBlade',
  displayPoweredByClearblade: false,
};
