exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#007bff",
	"secondary": "#6c757d",
	"success": "#28a745",
	"danger": "#dc3545",
	"header_background": "#fff",
	"header_text_color": "#000",
	"body_bg_color": "#fff",
	"body_text_color": "#000",
	"landing_page_loginButton": "#007bff",
	"landing_page_loginButtonFontColor": "#fff",
	"landing_page_mainContainerFontColor": "#fff",
	"landing_page_registrationFormWrapper": "#fff",
	"landing_page_registrationFormHeaderFontColor": "#000",
	"landing_page_submitButton": "#007bff",
	"landing_page_submitButtonFontColor": "#fff",
	"landing_page_platformOverviewContainer": "#000",
	"landing_page_platformOverviewFontColor": "#fff",
	"landing_page_platformOverviewServicesFontColor": "#007bff",
	"landing_page_platformOverviewOutlineButton": "#000",
	"landing_page_platformOverviewOutlineButtonBorder": "#007bff",
	"landing_page_platformOverviewOutlineButtonFontColor": "#007bff",
	"landing_page_platformOverviewButton": "#007bff",
	"landing_page_platformOverviewButtonFontColor": "#000",
	"landing_page_ipmOverviewContainer": "#f0f0f0",
	"landing_page_ipmOverviewButton": "#007bff",
	"landing_page_ipmOverviewButtonFontColor": "#fff",
	"landing_page_developerResourcesContainer": "#000",
	"landing_page_developerResourcesFontColor": "#fff",
	"landing_page_developerResourcesServicesFontColor": "#007bff",
	"landing_page_developerResourcesButton": "#007bff",
	"landing_page_developerResourcesButtonFontColor": "#000"
};