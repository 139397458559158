import WidgetModel from './WidgetModel';
import { createParserFunction } from '../plugin/utils/Parsers';
import { HTML_SETTING } from '../../../plugins/widgets/HTMLWidget/constants';
import {
  TypedSettingDefinition,
  DynamicDataSettingInstance,
  DataSettingDefinition,
  StaticOrCalculatedDataSettingInstance,
} from 'utils/types';

import { HTMLModel, HTMLWidgetSettings } from 'plugins/widgets/HTMLWidget/def';

interface HTMLSetting extends DynamicDataSettingInstance {
  value: HTMLModel;
}

class HTMLWidget extends WidgetModel<HTMLWidgetSettings> {
  createParserFunction(js: string) {
    // we always send false for isDebug because the debugger is handled inside of the component
    return createParserFunction(js, false, this.id);
  }

  formatDynamicSetting(def: TypedSettingDefinition, setting: HTMLSetting) {
    if (def.name === HTML_SETTING) {
      const formatted = {
        ...setting,
      };
      // The HTML widget's value is an object, so we prefix it with return and stringify it so that
      // it can be interpreted by the function constructor.
      // We also pass the calling context and arguments on through to the html widget so that
      // the function constructor can be recreated in widget
      if (formatted.incoming_parser && typeof formatted.incoming_parser.value === 'object') {
        formatted.incoming_parser = {
          ...formatted.incoming_parser,
          value: createHTMLParser(formatted.incoming_parser.value),
        };
      }

      return formatted;
    } else {
      return super.formatDynamicSetting(def, setting as DynamicDataSettingInstance);
    }
  }

  processStaticSetting(def: DataSettingDefinition, currentSetting: StaticOrCalculatedDataSettingInstance) {
    if (def.name === HTML_SETTING) {
      const thisForParser = this.getThisForParser(null, null, null);
      return {
        ...currentSetting.value,
        valueForThis: thisForParser,
        arguments: this.getArgumentsForParser(thisForParser),
      };
    } else {
      super.processStaticSetting(def, currentSetting as StaticOrCalculatedDataSettingInstance);
    }
  }
}

function createHTMLParser(parserValue: HTMLModel) {
  return `
    const htmlWidgetInfo = ${JSON.stringify(parserValue)};
    htmlWidgetInfo.valueForThis = this;
    htmlWidgetInfo.arguments = [].slice.call(arguments);
    return htmlWidgetInfo;
  `;
}

export default HTMLWidget;
