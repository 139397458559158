exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primary": "#4571b0",
	"secondary": "#900",
	"success": "#28a745",
	"danger": "#b50500",
	"white": "#fff",
	"gray100": "#f8f9fa",
	"gray200": "#e9ecef",
	"gray300": "#dee2e6",
	"gray400": "#ced4da",
	"gray500": "#adb5bd",
	"gray600": "#6c757d",
	"gray700": "#495057",
	"gray800": "#343a40",
	"gray900": "#212529",
	"black": "#000",
	"logo_color": "#fff",
	"header_background": "#4571b0",
	"header_text_color": "#fff",
	"landing_page_loginButton": "#900",
	"landing_page_loginButtonFontColor": "#000",
	"landing_page_mainContainerFontColor": "#fff",
	"landing_page_registrationFormWrapper": "#000",
	"landing_page_registrationFormHeaderFontColor": "#fff",
	"landing_page_submitButton": "#900",
	"landing_page_submitButtonFontColor": "#000",
	"landing_page_platformOverviewContainer": "#000",
	"landing_page_platformOverviewFontColor": "#fff",
	"landing_page_platformOverviewServicesFontColor": "#900",
	"landing_page_platformOverviewOutlineButton": "#000",
	"landing_page_platformOverviewOutlineButtonBorder": "#900",
	"landing_page_platformOverviewOutlineButtonFontColor": "#900",
	"landing_page_platformOverviewButton": "#900",
	"landing_page_platformOverviewButtonFontColor": "#000",
	"landing_page_ipmOverviewContainer": "#f0f0f0",
	"landing_page_ipmOverviewButton": "#900",
	"landing_page_ipmOverviewButtonFontColor": "#000",
	"landing_page_developerResourcesContainer": "#000",
	"landing_page_developerResourcesFontColor": "#fff",
	"landing_page_developerResourcesServicesFontColor": "#900",
	"landing_page_developerResourcesButton": "#900",
	"landing_page_developerResourcesButtonFontColor": "#000"
};