import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getInjectors from './reducerInjectors';
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux';
import { Reducer, AnyAction } from 'redux';
import { ConfiguredStore } from 'configureStore';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }: { key: string; reducer: Reducer<{}> }) => (WrappedComponent: React.ComponentType) => {
  class ReducerInjector extends React.Component {
    static WrappedComponent = WrappedComponent;

    static contextType = ReactReduxContext;

    static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any, context: ReactReduxContextValue<any, AnyAction>) {
      super(props, context);

      getInjectors(context.store as ConfiguredStore<{}>).injectReducer(key, reducer);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};

const useInjectReducer = ({ key, reducer }: { key: string; reducer: Reducer<{}> }) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    getInjectors(context.store as ConfiguredStore<{}>).injectReducer(key, reducer);
  }, []);
};

export { useInjectReducer };
