import { cbFetch, platformFetch, AllowedMethods, consoleFetch } from 'cb-utils/cbFetch';
import { getPlatformInfo, storePlatformConfig } from 'cb-utils/platformInfo';
import {
  setDevTokenToStorage,
  getDevTokenFromStorage,
  setRefreshTokenToStorage,
  getRefreshTokenFromStorage,
} from 'cb-utils/devToken';
import { EdgeModel, DevUserModel, TwoFactorAuthMethods } from 'cb-utils/console-entity-models';

export const changePassword = (body: { newPassword: string; oldPassword: string }) => {
  const update = {
    new_password: body.newPassword,
    old_password: body.oldPassword,
  };
  return platformFetch('/admin/putpass', {
    method: AllowedMethods.PUT,
    body: JSON.stringify(update),
  });
};

interface UserLogin {
  email: string;
  password: string;
}

interface RefreshDetails {
  dev_token: string;
  refresh_token: string;
  expires_at: number;
}

interface BaseUserLoginResponse {
  dev_token: string;
  is_two_factor: boolean;
  refresh_token?: string;
  expires_at?: number;
}

export interface TwoFactorUserLoginResponse extends BaseUserLoginResponse {
  next_step_url: string;
  intermediate_token: string;
  two_factor_method: TwoFactorAuthMethods;
  otp_id: string;
  otp_issued: string;
}

export const refreshLogin = (refresh_token: string): Promise<RefreshDetails> => {
  const platformInfo = getPlatformInfo();
  const access_token = getDevTokenFromStorage();
  // when this is called on load, for someone not logged in, do nothing
  if (!access_token) return;
  return cbFetch(`${platformInfo.url}/admin/auth`, {
    method: AllowedMethods.POST,
    body: JSON.stringify({
      grant_type: 'refresh_token',
      access_token,
      refresh_token,
    }),
  });
};

/**
 * to automatically keep user logged in, uses the refresh_token provided on initial login in
 * (or the value in local storage if on refresh) and gets a new devToken
 */
export const continuouslyRefreshLoginConsole = (
  {
    expires_at = getRefreshTokenFromStorage().expires_at,
    refresh_token = getRefreshTokenFromStorage().refresh_token,
    dev_token = getDevTokenFromStorage(),
  } = {} as Partial<RefreshDetails>,
) => {
  const timeToExpiration = expires_at * 1000 - Date.now();
  if (!refresh_token) return;
  setDevTokenToStorage(dev_token);
  setRefreshTokenToStorage({ expires_at, refresh_token });

  setTimeout(() => {
    refreshLogin(refresh_token).then(continuouslyRefreshLoginConsole);
    // start refresh a minute before it expires, so that even if it takes a while will still be valid
  }, timeToExpiration - 60 * 1000);
};

export const userLogin = (c: AbortController, body: UserLogin): Promise<TwoFactorUserLoginResponse> => {
  const platformInfo = getPlatformInfo();
  return cbFetch(`${platformInfo.url}/admin/auth`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  }).then((resp: TwoFactorUserLoginResponse) => {
    if (resp.is_two_factor) {
      setDevTokenToStorage(resp.intermediate_token);
    } else {
      setDevTokenToStorage(resp.dev_token);
    }
    if (resp.refresh_token) {
      continuouslyRefreshLoginConsole({ expires_at: resp.expires_at, refresh_token: resp.refresh_token });
    }
    storePlatformConfig(platformInfo);
    return resp;
  });
};

interface TwoFactorVerifyBody {
  code: string;
  two_factor_method: TwoFactorAuthMethods;
  otp_id: string;
  otp_issued: string;
}

export const verifyTwoFactorAuth = (c: AbortController, body: TwoFactorVerifyBody): Promise<{ dev_token: string }> => {
  return platformFetch('/admin/auth/verify', {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  }).then((res: RefreshDetails) => {
    continuouslyRefreshLoginConsole(res);
    return res;
  });
};

interface UserRegistration extends UserLogin {
  fname: string;
  lname: string;
  org: string;
  phone?: string;
}

export interface UserRegistrationResponse {
  user_id: string;
  result: string;
  is_two_factor: boolean;
  dev_token: string;
  expires_at?: number;
  refresh_token?: string;
  intermediate_token: string;
  next_step_url: string;
  otp_id: string;
  otp_issued: string;
  two_factor_method: TwoFactorAuthMethods;
}

export const userRegistration = (c: AbortController, info: UserRegistration): Promise<UserRegistrationResponse> => {
  const platformInfo = getPlatformInfo();
  return cbFetch(`${platformInfo.url}/admin/reg`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(info),
  }).then((resp: UserRegistrationResponse) => {
    if (resp.refresh_token) {
      continuouslyRefreshLoginConsole({ expires_at: resp.expires_at, refresh_token: resp.refresh_token });
    }
    setDevTokenToStorage(resp.is_two_factor ? resp.intermediate_token : resp.dev_token);
    storePlatformConfig(platformInfo);
    return resp;
  });
};

export const sendRegistrationEmail = (info: UserRegistration) => {
  const { email, fname, lname, org } = info;
  return consoleFetch('/console-api/saas/email', {
    method: AllowedMethods.POST,
    body: JSON.stringify({ email, fname, lname, org }),
  });
};
export interface ResendOTPResponse {
  otp_id: string;
  otp_issued: string;
}

export const resendOTP = (): Promise<ResendOTPResponse> => {
  return platformFetch('/admin/auth/otp/regen', { method: AllowedMethods.POST });
};

export interface EdgeLogin {
  email: string;
  password: string;
  edgeId: string;
  systemKey: string;
  edge: EdgeModel;
}

export interface EdgeLoginSuccess {
  name: string;
  devToken: string;
  systemKey: string;
  edge: EdgeModel;
}

export const edgeLogin = ({ email, password, edgeId, systemKey }: EdgeLogin) => {
  return cbFetch(`${getPlatformInfo().url}/admin/auth`, {
    method: AllowedMethods.POST,
    body: JSON.stringify({ email, password }),
    headers: {
      'ClearBlade-Edge': edgeId,
      'Clearblade-SystemKey': systemKey,
    },
  }).then(({ dev_token }) => {
    return {
      devToken: dev_token,
      name: edgeId,
      systemKey,
    } as Partial<EdgeLoginSuccess>;
  });
};

export const userLogout = () => {
  return platformFetch('/admin/logout', {
    method: AllowedMethods.POST,
  }).then(() => {
    delete localStorage['ngStorage-cb_platform_dev_token'];
    delete localStorage['ngStorage-dev_info'];
    delete localStorage['ngStorage-system'];
    delete localStorage.github_access_token;
    delete localStorage.code;
    delete localStorage['ngStorage-cb_available_edges'];
    delete localStorage['ngStorage-cb_edge'];
    window.location.replace('/');
  });
};

export const getCurrentUser = (): Promise<DevUserModel> => {
  return platformFetch(`/admin/userinfo`, {
    method: AllowedMethods.GET,
  });
};

export interface UpdateCurrentUserPayload {
  two_factor_method?: TwoFactorAuthMethods;
  phone?: string;
  two_factor_enabled?: boolean;
}

export const updateCurrentUser = (controller: AbortController, body: UpdateCurrentUserPayload) => {
  return platformFetch(`/admin/userinfo`, {
    method: AllowedMethods.PUT,
    body: JSON.stringify(body),
  });
};

export const checkEdgeAuthToken = (edgeName: string) => {
  return platformFetch(
    '/admin/checkauth',
    {
      method: AllowedMethods.POST,
    },
    undefined,
    edgeName,
  ).then(res => res.is_authenticated);
};

export const checkAuthToken = () => {
  return platformFetch('/admin/checkauth', {
    method: AllowedMethods.POST,
  }).then(res => res.is_authenticated);
};
