import DatasourceModel from './DatasourceModel';
import { DATASOURCES_SETTING } from '../../../plugins/datasources/utility_datasource_aggregator/constants';

class DatasourceAggregator extends DatasourceModel {
  processUpdateToSettings() {
    this.clearSubscriptions();

    let rtn = {};
    const settings = this.settings();

    const datasourcesToListenFor = settings[DATASOURCES_SETTING];
    if (datasourcesToListenFor) {
      for (let i = 0, len = datasourcesToListenFor.length; i < len; i += 1) {
        const datasource = this.portalModel.getDatasourceInstanceById(datasourcesToListenFor[i]);
        if (!datasource) {
          continue;
        }
        const dsName = datasource.name;
        rtn = {
          ...rtn,
          [dsName]: datasource.latestData(),
        };

        const subCallback = this.handleChildDatasourceUpdate.bind(this, dsName);
        datasource.latestData.subscribe(subCallback); // we subscribe to the datasource so that it can "talk directly" to the aggregator
        this.datasourceSubscriptions.push(datasource.latestData.unsubscribe.bind(datasource.latestData, subCallback)); // we keep a reference of a bound function so that we can easily unsubscribe from datasources whenever settings change
      }
    }

    this.updateCallback(rtn);
    return rtn;
  }

  getModel() {
    let rtn = {};
    const datasourcesToListenFor = this.getSettingByName(DATASOURCES_SETTING);
    if (datasourcesToListenFor) {
      for (let i = 0, len = datasourcesToListenFor.length; i < len; i += 1) {
        const datasource = this.portalModel.getDatasourceInstanceById(datasourcesToListenFor[i]);
        if (!datasource) {
          continue;
        }
        const dsName = datasource.name;
        rtn = {
          ...rtn,
          [dsName]: datasource,
        };
      }
    }
    return rtn;
  }

  handleChildDatasourceUpdate(dsName: string, data: ChildDataUpdate) {
    const newData = {
      ...this.latestData(),
      [dsName]: data,
    };
    this.updateCallback(newData);
  }

  doesListenToDatasource(id: string) {
    const datasourcesToListenFor = this.settings()[DATASOURCES_SETTING];
    if (datasourcesToListenFor) {
      return datasourcesToListenFor.indexOf(id) > -1;
    }
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChildDataUpdate = any;

export default DatasourceAggregator;
