import ClearBladeCodeService from '../../AlertProviders/codeService/ClearBlade';
import { AvailableAlertTypes, AlertDefinition } from '../types';

const CodeServiceAlert: AlertDefinition<{}> = {
  name: AvailableAlertTypes.CODE_SERVICE,
  label: 'Code Service',

  providers: [ClearBladeCodeService],

  fields: [],
};

export default CodeServiceAlert;
