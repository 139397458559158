export function isJson(input: string) {
  try {
    JSON.parse(input);
  } catch (error) {
    return false;
  }
  return true;
}

// adds tabs and new lines to json
export default function formatJson(input: string | object) {
  let formattedInput;
  if (typeof input === 'string') {
    formattedInput = input;
    try {
      formattedInput = JSON.stringify(JSON.parse(input), null, 2);
    } catch (e) {
      // doesn't matter, will just return original string if not json
    }
  } else {
    formattedInput = JSON.stringify(input, null, 2);
  }
  return formattedInput;
}

export function stripExtraSpacesJson(str: string) {
  // otherwise it is saved with spaces and new lines which can cause problems
  // extra spaces could break a regex looking for part of a json object
  let returnStr = str;
  try {
    returnStr = JSON.stringify(JSON.parse(str));
  } catch (e) {
    // ignore this, it's fine if it's not json
  }
  return returnStr;
}
