import styled from 'styled-components';

interface WrapperProps {
  forPage?: boolean;
  overlay?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  margin: 0 auto;
  width: ${props => (props.forPage ? '25vw' : '35px')};
  height: ${props => (props.forPage ? '25vw' : '35px')};
  top: ${props => (props.forPage ? '25vh' : '0px')};
  z-index: ${props => (props.forPage || props.overlay ? '100000000000' : '1')};
  position: ${props => (props.forPage || props.overlay ? 'absolute' : 'relative')};
  left: ${props => (props.forPage || props.overlay) && '0'};
  right: ${props => (props.forPage || props.overlay) && '0'};
`;

export default Wrapper;
