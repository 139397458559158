import { defineMessages } from 'react-intl';

export default defineMessages({
  errorRequired: {
    id: 'app.components.NewDeviceModal.errorRequired',
    defaultMessage: 'Required',
  },
  errorStartWithNum: {
    id: 'app.components.NewDeviceModal.errorStartWithNum',
    defaultMessage: 'Cannot start with a number',
  },
  errorSpaces: {
    id: 'app.components.NewDeviceModal.errorSpaces',
    defaultMessage: 'Cannot have spaces',
  },
  errorSpecialCharacters: {
    id: 'app.components.NewDeviceModal.errorSpecialCharacters',
    defaultMessage: 'Cannot have special characters',
  },
  errorEmail: {
    id: 'app.components.LandingRegister.errorEmail',
    defaultMessage: 'Must be valid email address',
  },
  errorPhoneNumber: {
    id: 'app.components.LandingRegister.errorEmail',
    defaultMessage: 'Must be valid phone number. e.g., +11234567890',
  },
  errorPasswordMatch: {
    id: 'app.components.LandingRegister.errorPasswordMatch',
    defaultMessage: 'Passwords must match',
  },
  errorMinimum: {
    id: 'app.components.ServiceCacheEditModal.errorMinimum',
    defaultMessage: 'Value cannot be less than {min} {units}',
  },
  errorMaximum: {
    id: 'app.components.ServiceCacheEditModal.errorMaximum',
    defaultMessage: 'Value cannot be greater than {max} {units}',
  },
});
